import { useEffect, useRef, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { Message } from "../../../entities/Case/models/types/Case.types";


const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_URL;

const useChat = (roomId: string) => {
    const { t } = useTranslation();

    const [isQueueComplete, setIsQueueComplete] = useState<boolean>(false);

    const [messages, setMessages] = useState<Message[]>([]);

    const websocketRef = useRef<WebSocket | null>(null);
    const reconnectIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const reconnectAttemptsRef = useRef<number>(0); 

    useEffect(() => {
        if (!roomId) {
            console.error("Invalid roomId, cannot connect WebSocket.");
            return;
        }

        const connectWebSocket = () => {
            try {
                const ws = new WebSocket(`${SOCKET_SERVER_URL}/ws/${roomId}`);
                websocketRef.current = ws;

                ws.onopen = () => {
                    console.log(`WebSocket connection opened to ${roomId}`);
                    clearIntervalIfActive();
                    reconnectAttemptsRef.current = 0; 
                };

                ws.onmessage = (event: MessageEvent) => {
                    try {
                        const data = event.data;
                        if (typeof data === 'string' && data.startsWith('{')) {
                            const parsedResponse: Message = JSON.parse(data);
                            setMessages((prevMessages) => [...prevMessages, parsedResponse]);
                        } else {
                            console.error("Invalid JSON data received:", data);
                        }
                    } catch (error) {
                        toast.info(t('messages.info.try_later'));
                        console.error("Message parsing error:", error);
                    }
                    setIsQueueComplete(false);
                };

                ws.onclose = () => {
                    console.log("WebSocket connection closed");
                };

                ws.onerror = (_: Event) => {
                    console.log("WebSocket connection closed, attempting to reconnect...");
                    // console.error("[WebSocket error]:", error);
                    
                    if (reconnectAttemptsRef.current >= 10) {
                        toast.error(t("messages.errors.socket_error"));
                        clearIntervalIfActive();
                    }
                };
            } catch (error) {
                console.error("WebSocket connection error:", error);
                attemptReconnect();
            }
        };

        const attemptReconnect = () => {
            reconnectAttemptsRef.current++;
            const delay = Math.min(1000 * 2 ** reconnectAttemptsRef.current, 30000); 
            reconnectIntervalRef.current = setTimeout(connectWebSocket, delay);
        };

        const clearIntervalIfActive = () => {
            if (reconnectIntervalRef.current) {
                clearTimeout(reconnectIntervalRef.current);
                reconnectIntervalRef.current = null;
            }
        };

        connectWebSocket();

        return () => {
            if (websocketRef.current) {
                websocketRef.current.close();
            }
            clearIntervalIfActive();
        };
    }, [roomId, t]);

    const sendMessage = (content: string) => {
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            setIsQueueComplete(true);
            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    sender: "user_proxy",
                    recipient: "assistant",
                    content: content,
                },
            ]);

            websocketRef.current.send(content);
        } else {
            toast.error(t("messages.errors.socket_error"));
        }
    };

    return { messages, sendMessage, isQueueComplete };
};

export default useChat;
