import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { isEmail } from "../../../shared/utils/validators";

import TextButton from "../../Buttons/TextButton";
import PrimaryButton from "../../Buttons/PrimaryButton";

import Input from "../../Inputs/Input";
import InputPassword from "../../Inputs/InputPassword";

import Text from "../../../shared/lib/widgets/Text";
import Container from "../../../shared/lib/widgets/Container";
import RowWidget from "../../../shared/lib/widgets/RowWidget";
import { toast } from "react-toastify";


const AffiliateSignIn = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    const [form, setForm] = useState({
        email: '',
        password: '',
    });
    
    const [errorState, setErrorState] = useState({
        email: '',
        password: '',
    });
    
    const validate = () => {
        const newErrors = {
            email: '',
            password: '',
        };
        
        if (!isEmail(form.email))
            newErrors.email = t('messages.errors.input.email');
        
        if (form.password.length < 8)
            newErrors.password = t('messages.errors.input.password');
        
        if (form.password.length < 8)
            newErrors.password = t('messages.errors.input.password');

        return newErrors;
    };
    
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        setForm({
            ...form,
            [name]: value,
        });
    };

    const handleSignUp = (e: any) => {
        e.preventDefault();
        
        const validationErrors = validate();
        if (Object.values(validationErrors).some(error => error))
            return setErrorState(validationErrors);

        toast.info('This part is under development.');
        navigate('/affiliate');
    }

    return (
        <RowWidget $width="100%" $justifyContent="center" $alignItems="center" $margin="32px 0">
            <RowWidget $width="360px" $flexDirection="column" $justifyContent="space-between">
                <Container $margin="40px 0 32px 0" $height="100%">
                    <RowWidget $justifyContent="center">
                        <img style={{ height: '32px', marginRight: '8px', }} src="/images/img_group.svg" alt="logo" />
                        <img style={{ height: '32px' }} src="/images/img_group_light_blue_a700.svg" alt="logo" />
                    </RowWidget>

                    <Container $height="64px" />

                    <Text $fontWeight="bold" $fontSize="28px" $textAlign="center">{t('affiliate.sign_in.title')}</Text>

                    <Container $height="8px" />

                    <Text $color="#908F94" $textAlign="center" $fontSize="14px">{t('affiliate.sign_in.subtitle')}</Text>

                    <Container $height="32px" />

                    <Input 
                        label={t('input.user_email_label')}
                        placeholder={t('input.email_placeholder')}
                        name='email'
                        value={form.email}
                        onChange={handleInputChange}
                        errorMessage={errorState.email}
                    />
                    
                    <Container $height="12px" />

                    <InputPassword 
                        label={t('input.new_password_label')}
                        placeholder={t('input.password_placeholder')}
                        name='password'
                        value={form.password}
                        onChange={handleInputChange}
                        errorMessage={errorState.password}
                    />

                    <Container $height="32px" />

                    <PrimaryButton 
                        onClick={handleSignUp}
                        title={t('auth.sign_in.title')}
                        $width="100%"
                    />
                    
                    <Container $height="20px" />

                    <RowWidget $justifyContent="center">
                        <Text $margin="0 4px" $fontSize="14px" $color="#46454D">{t('auth.sign_in.no_account')}</Text>
                        <TextButton 
                            onClick={() => navigate('/affiliate/sign-up')}
                            title={t('auth.sign_up.title')}
                            fontWeight="bold" 
                            fontSize="14px" 
                            color="#007FFF" 
                        />
                    </RowWidget>
                </Container>
                
                <Text $textAlign="center" $color="#7E7D83" $fontSize="14px">Copyright © 2024 LegalAssists</Text>
            </RowWidget>
        </RowWidget>
    );
}


export default AffiliateSignIn;
