import React from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";

import SecondaryButton from "../Buttons/SecondaryButton";

import { Agent } from "../../entities/Agents/models/types/Agent.types";


const SubscribetAgentCardStyle = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 200px 200px 120px;
    gap: 8px;
    align-items: center;
    padding: 0 16px;
    border-radius: 12px;
    border: 1px solid #E9E9EA;
    padding: 16px;
    background-color: #FFF;
    margin-bottom: 10px;
`;

const SubscribetAgentCardItem = styled.div`
    display: flex;
    align-items: center;
    color: #201F29;
    font-size: 16px;
    font-family: 'SF Pro Display';
`;

interface SubscribetAgentCardProps {
    agentInfo: Agent;
    purchaseDate: string;
    isPaid: boolean;
}

const SubscribetAgentCard: React.FC<SubscribetAgentCardProps> = ({ agentInfo, purchaseDate, isPaid }) => {
    const navigate = useNavigate();
    
    return (
        <SubscribetAgentCardStyle>
            <SubscribetAgentCardItem>
                <img src={agentInfo.profileImage} alt="agent" style={{ height: '40px', width: '40px', borderRadius: '50%', objectFit: 'cover', marginRight: '12px' }} />

                <Text $fontWeight="bold" onClick={() => navigate(`/marketplace/${agentInfo.id}`)} style={{ cursor: 'pointer' }}>{agentInfo.name}</Text>
            </SubscribetAgentCardItem>

            <SubscribetAgentCardItem>EUR €{agentInfo.price}</SubscribetAgentCardItem>
            
            <SubscribetAgentCardItem>{purchaseDate}</SubscribetAgentCardItem>
            
            <SubscribetAgentCardItem>
                {
                    isPaid ? 
                        <RowWidget $justifyContent="center" $width="100%">
                            <Container $width='100px' $padding='8px 24px' $backgroundColor='#EDF8E8' $borderRadius='18px'>
                                <Text $color='#4EB61C' $textAlign='center'>Pagato</Text>
                            </Container>
                        </RowWidget>
                    : 
                    <SecondaryButton 
                        onClick={() => navigate(`/marketplace/${agentInfo.id}`)}
                        title='Aggiorna'
                        $width="100%"
                    />
                }
            </SubscribetAgentCardItem>
        </SubscribetAgentCardStyle>
    );
}

export default SubscribetAgentCard;