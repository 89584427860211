import { useState } from "react";

import { useTranslation } from "react-i18next";

import Input from "../Inputs/Input";

import Popup from "./Popup"
import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";

interface RenamePopupProps {
    onSubmit: (newName: string) => void;
    onClose: () => void;
}

const RenamePopup: React.FC<RenamePopupProps> = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();
    
    const [newName, setNewName] = useState<string>('');
    
    const [error, setError] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!newName.trim())
            return setError(t('messages.errors.input.empty'));
        
        onSubmit(newName);
    };

    const handleInputChange = (e: any) => {
        setNewName(e.target.value);
    };
    return (
        <Popup title={t('my_cases.popup.rename.title')} onClose={onClose}>
            <RowWidget $flexDirection='column'>
                <Text $margin="0 0 16px 0">{t('my_cases.popup.rename.subtitle')}</Text>

                <Input 
                    value={newName} 
                    placeholder={t('input.case_name_label')} 
                    onChange={handleInputChange} 
                    label={t('input.case_name_placeholder')} 
                    errorMessage={error}
                />

                <RowWidget $justifyContent="space-between"  $margin="16px 0 0 0" $gap="16px">
                    <SecondaryButton 
                        onClick={onClose}
                        title={t('button.cancel')}
                        $width='120px'
                        $borderColor='#E9E9EA'
                    />
                    <PrimaryButton 
                        onClick={(e: any) => handleSubmit(e)}
                        title={t('button.save')}
                        $width='160px'
                    />
                </RowWidget>
            </RowWidget>
        </Popup>
    );
};

export default RenamePopup;