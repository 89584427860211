import { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

import CrossIcon from '../../assets/CrossIcon.svg'

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from '../../shared/lib/widgets/Container';

import '../../shared/lib/widgets/document-preview.css';


const DocumentPopupStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
    display: flex;
    justify-content: end;
    cursor: auto;
`;

const DocumentPopupHeader = styled.div`
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E9E9EA;
    padding: 20px;
`;

const DocumentPopupSubheader = styled.div`
height: 64px;
display: grid;
place-items: center;
border-bottom: 1px solid #E9E9EA;
`;

const DocumentPreview = styled.div`
    background-color: #E8EBF0;
    height: 100%;
    width: 100%;
    padding: 20px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: #c7c7c7;
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 40px;
        background: #aaa;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #7c7c7c;
    }
`;

interface DocumentPopupPopup {
    onClose: () => void;
    file: File;
    index: number;
}

const DocumentPopup: React.FC<DocumentPopupPopup> = ({ onClose, file, index }) => {
    const documentPopupRef = useRef<HTMLDivElement>(null);

    const [doc, setDoc] = useState<{ uri: string } | null>(null);

    // const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const file = event.target.files?.[0];
    //     if (file) {
    //         const fileURL = await uploadFileToServer(file);
    //         setDoc({ uri: fileURL });
    //     }
    // };

    // const uploadFileToServer = async (file: File) => {
    //     return "https://example.com/path-to-your-uploaded-docx-file.docx";
    // };

    useEffect(() => {
        const handlerClick = (e: MouseEvent) => {
            if (documentPopupRef.current && !documentPopupRef.current.contains(e.target as Node)) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handlerClick);
        return () => {
            document.removeEventListener("mousedown", handlerClick);
        };
    }, [onClose]);

    useEffect(() => {
        if (file) {
            const fileReader = new FileReader();

            fileReader.onloadend = () => {
                const result = fileReader.result;
                if (typeof result === "string") {
                    setDoc({ uri: result });
                }
            };

            fileReader.readAsDataURL(file);
        }
    }, [file]);

    return (
        <DocumentPopupStyle>
            <Container ref={documentPopupRef} $width="660px" $height="100%" $backgroundColor="#FFF">
                <DocumentPopupHeader>
                    <RowWidget $gap="8px">
                        <Text $fontSize="18px" $color="#007FFF" $fontWeight="bold">[{index}]</Text>
                        <Text $fontSize="18px" $fontWeight="bold">{file?.name}</Text>
                    </RowWidget>

                    <img
                        src={CrossIcon}
                        alt="icon"
                        onClick={(e) => {e.stopPropagation(); onClose()}}
                        style={{
                            width: "26px",
                            height: "26px",
                            borderRadius: "50%",
                            cursor: "pointer",
                            opacity: ".7",
                            padding: "6px",
                        }}
                    />
                </DocumentPopupHeader>

                <DocumentPopupSubheader>
                    <Container>
                        <span style={{ fontWeight: "bold" }}>x</span>
                        <span>/x pages</span>
                    </Container>
                </DocumentPopupSubheader>

                <DocumentPreview>
                    {
                        doc ? 
                            <DocViewer
                                documents={[doc]}
                                pluginRenderers={DocViewerRenderers}
                                language="en"
                                config={{
                                    pdfVerticalScrollByDefault: true,
                                    header: {
                                        disableHeader: false,
                                        disableFileName: false,
                                        retainURLParams: false,
                                    },
                                }}
                                style={{
                                    borderRadius: '6px',
                                    height: 'fit-content',
                                    backgroundColor: '#FFF',
                                }}
                            />
                            : 
                            <pre>Loading document...</pre>
                    }
                </DocumentPreview>
            </Container>
        </DocumentPopupStyle>
    );
};

export default DocumentPopup;
