import React from "react";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


interface InfoCardProps {
    icon: string;
    title: string;
    subtitle: string;
}

const InfoCard: React.FC<InfoCardProps> = ({ icon, title, subtitle }) => {
    return (
        <Container $borderRadius='16px' $border='1px solid #E9E9EA' $width='100%' $padding='16px 12px' style={{ userSelect: 'none' }}>
            <RowWidget $alignItems='center' $gap='12px'>
                <div style={{
                    height: '48px',
                    width: '48px',
                    borderRadius: '50%',
                    backgroundColor: '#E6F2FF',
                    display: 'grid',
                    placeItems: 'center',
                }}>
                    <img 
                        src={icon} 
                        alt="icon" 
                        style={{
                            height: '24px',
                            width: '24px',
                        }}
                    />
                </div>

                <Container>
                    <Text $color='#7E7D83' $fontWeight='bold' $fontSize='14px'>{title}</Text>
                    <Text $fontSize='24px' $fontWeight='bold'>{subtitle}</Text>
                </Container>
            </RowWidget>
        </Container>
    );
}


export default InfoCard;