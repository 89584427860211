
import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ThreeDotsIcon from "../../assets/ThreeDotsIcon.svg";

import EditIcon from '../../assets/greyEditIcon.svg';
import DeleteIcon from '../../assets/DeleteIcon.svg';
import ShareIcon from '../../assets/greyShareIcon.svg';
import ArchiveIcon from '../../assets/greyArchive.svg';

import { Case } from '../../entities/Case/models/types/Case.types';
import { deleteCase, updateCase } from '../../entities/Cases/models/services/cases.services';

import { useAppDispatch } from '../../shared/components/useAppDispatch/useAppDispatch';

import ArchivePopup from '../Popups/ArchivePopup';
import DeletePopup from '../Popups/DeletePopup';
import RenamePopup from '../Popups/RenamePopup';

import Tooltip from '../../shared/lib/widgets/Tooltip';

import Text from '../../shared/lib/widgets/Text';
import Container from '../../shared/lib/widgets/Container';
import { useAppSelector } from '../../shared/components/useAppSelector/useAppSelector';
import { StateSchema } from '../../app/providers/StoreProvider/config/StateSchema';


const ActionMenuStyle = styled.div`
    position: relative;
`;

const ActionMenuMoreVertical = styled.img`
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    padding: 4px;
    &:hover {
        background-color: #E8EBF0;
    }
`;

const ActionMenuItemsStyle = styled.div`
    position: absolute;
    top: calc(100% + 1px);
    right: 0;
    z-index: 1;
    border: 1px solid #E9E9EA;
    background-color: #FFF;
    border-radius: 8px;
    width: 160px;
`;

const ActionItemIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 8px;
`;

const ActionItem = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 12px;
    &:hover {
        opacity: .7;
    }
`;

interface CaseCardProps {
    caseData?: Case;
}

const ActionMenu: React.FC<CaseCardProps> = ({ caseData }) => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const user = useAppSelector((state: StateSchema) => state.user.userData);

    const actionMenuRef = useRef<HTMLDivElement>(null);
    
    const [isVisible, setIsVisible] = useState(false);
    
    const [showRenameAccount, setRenameAccount] = useState(false);
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [isArchive, setArchive] = useState(false);

    const handleRename = (newName: string) => {
        dispatch(updateCase({ id: caseData?.id, name: newName })); 
        toast.success(t('messages.success.case_name_changed'));
        setRenameAccount(false);
    };
    
    useEffect(() => {
        const handlerClick = (e: MouseEvent) => {
            if (actionMenuRef.current && !actionMenuRef.current.contains(e.target as Node)) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handlerClick);
        return () => {
            document.removeEventListener('mousedown', handlerClick);
        };
    }, []);
    
    return (
        <ActionMenuStyle ref={actionMenuRef} onClick={(e) => e.stopPropagation()}>
            <Tooltip
                title={t('tooltip.quick_action.title')}
                description={t('tooltip.quick_action.description')}
            >
                <ActionMenuMoreVertical 
                    src={ThreeDotsIcon} 
                    alt="icon"
                    onClick={() => setIsVisible(!isVisible)} 
                />
            </Tooltip>

            {
                isVisible &&
                <ActionMenuItemsStyle>
                    <ActionItem onClick={() => setRenameAccount(true)} >
                        <ActionItemIcon src={EditIcon} width='16px' />
                        <Text $fontWeight="400" $fontSize='14px' $color="#201F29">{t('my_cases.action_menu.rename')}</Text>
                    </ActionItem>

                    <ActionItem onClick={() => navigate(`/case/${caseData?.id}`)}>
                        <ActionItemIcon src={ShareIcon} width='16px'  />
                        <Text $fontWeight="400" $fontSize='14px' $color="#201F29">{t('my_cases.action_menu.share')}</Text>
                    </ActionItem>

                    <ActionItem onClick={() => setArchive(true)}>
                        <ActionItemIcon src={ArchiveIcon} width='16px' />
                        <Text $fontWeight="400" $fontSize='14px' $color="#201F29">{!caseData?.is_archived ? t('my_cases.action_menu.archive') : t('my_cases.action_menu.unarchive')}</Text>
                    </ActionItem>

					<Container $width="100%" $height="1px" $backgroundColor="#E9E9EA" />

                    <ActionItem onClick={() => setPopupVisible(true)}>
                        <ActionItemIcon src={DeleteIcon} width='16px' />
                        <Text $fontWeight="400" $fontSize='14px' $color="#201F29">{t('my_cases.action_menu.delete')}</Text>
                    </ActionItem>
                </ActionMenuItemsStyle>
            }

            {
                isArchive && 
                <ArchivePopup 
                    text1={!caseData?.is_archived ? <div>{t('my_cases.popup.archive.subtitle_archive')} <b>{caseData?.name}.</b></div> : <div>{t('my_cases.popup.archive.subtitle_archive')} <b>{caseData?.name}.</b></div>}
                    text2={!caseData?.is_archived ? <Trans>{t('my_cases.popup.archive.description_archive')}</Trans> : <Trans>{t('my_cases.popup.archive.description_unarchive')}</Trans>}
                    submitTitle={!caseData?.is_archived ? t('button.archive_case') : t('button.unarchive_case')}
                    onClose={() => setArchive(false)}
                    onSubmit={() => {
                        dispatch(updateCase({ id: caseData?.id, is_archived: !caseData?.is_archived }))
                        toast.success(`${caseData?.name} caso ${!caseData?.is_archived ? 'archiviato' : 'disarchiviato'}!`);
                        setArchive(false);
                    }}
                />
            }
            {
                isPopupVisible && 
                <DeletePopup 
                    text1={<div>{t('my_cases.popup.delete.subtitle')} <b>{caseData?.name}.</b></div>}
                    text2={<div>{t('my_cases.popup.delete.description')}</div>}
                    onClose={() => setPopupVisible(false)}
                    onSubmit={() => {
                        if (caseData?.creator_id !== user?.id)
                            return toast.error(t('messages.info.delete_permession'));
                        dispatch(deleteCase(caseData!.id))
                        toast.success(`${caseData?.name} ${t('messages.success.case_deleted')}`);
                        setPopupVisible(false);
                    }}
                />
            }
            {
                showRenameAccount &&
                <RenamePopup 
                    onSubmit={handleRename} 
                    onClose={() => setRenameAccount(false)} 
                />
            }
        </ActionMenuStyle>
    );
};

export default ActionMenu;

