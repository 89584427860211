import React from "react";

import { useTranslation } from "react-i18next";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";

import Popup from "./Popup"
import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

interface ArchivePopupProps {
    onSubmit: () => void;
    onClose: () => void;
    text1: React.ReactNode;
    text2: React.ReactNode;
    submitTitle: string;
}

const ArchivePopup: React.FC<ArchivePopupProps> = ({ onSubmit, onClose, text1, text2, submitTitle }) => {
    const { t } = useTranslation();

    return (
        <Popup title={t('my_cases.popup.archive.title')} onClose={onClose}>
            <RowWidget $flexDirection='column'>
                <Text $fontSize="16px" $fontWeight="400" $margin="0 0 16px 0">{text1}</Text>
                <Text $fontSize="16px" $fontWeight="400">{text2}</Text>

                <RowWidget $justifyContent="space-between" $margin="16px 0 0 0" $gap="16px">
                    <SecondaryButton 
                        onClick={onClose}
                        title={t('button.cancel')}
                        $width='120px'
                        $borderColor='#E9E9EA'
                    />
                    <PrimaryButton 
                        onClick={onSubmit}
                        title={submitTitle}
                        $width='160px'
                    />
                </RowWidget>
            </RowWidget>
        </Popup>
    );
};

export default ArchivePopup;