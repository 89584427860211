import React from "react";

import { useTranslation } from 'react-i18next';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import InputComponent from "./InputComponent";


interface InputPhoneProps {
    placeholder?: string;
    value: string;
    onChange: (e: any) => void;
    errorMessage?: string;
}

const InputPhone: React.FC<InputPhoneProps> = ({
    placeholder,
    value,
    onChange,
    errorMessage,
}) => {
    const { t } = useTranslation();

    return (
        <InputComponent label={t('input.user_phone_label')} errorMessage={errorMessage}>
            <PhoneInput
                countrySelectorStyleProps={{
                    buttonStyle: {
                        height: '100%',
                        padding: '0 4px',
                        backgroundColor: errorMessage ? '#FFEEEE' : '#f6f7f9',
                        borderRadius: '6px 0 0 6px',
                        border: errorMessage ? '1px solid #FF5252' : '1px solid transparent',
                    },
                    className: 'custom-phone-button',
                }}
                inputStyle={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: errorMessage ? '#FFEEEE' : '#f6f7f9',
                    borderRadius: '0 6px 6px 0',
                    border: errorMessage ? '1px solid #FF5252' : '1px solid transparent',
                    fontSize: '14px',
                }}
                style={{
                    height: '40px',
                }}
                defaultCountry="it"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </InputComponent>
    );
};

export default InputPhone;
