import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LinkCopyIcon from '../assets/SharelinkIcon.svg';
import LinkIcon from '../assets/link_earn.svg';
import AddUserIcon from '../assets/add_user_earn.svg';
import CurrencyIcon from '../assets/currency_earn.svg';
import WalletIcon from '../assets/wallet_icon.svg';
import ClockIcon from '../assets/clock_icon.svg';
import PayoutIcon from '../assets/payout_icon.svg';
import SupportIcon from '../assets/question_assistance_icon.svg';

import InfoCard from '../components/Cards/InfoCard';

import Input from '../components/Inputs/Input';

import ReferralsTable from '../components/Tables/ReferralsTable';

import TabBarButtons from '../components/Buttons/TabBarButtons';

import { AccountDetailsPopup, ConfirmPayoutWithdrawalPopup, PayPalDetailsPopup, SelectPayoutMethodPopup, SuccessWithdrawlPopup } from '../components/Popups/AffiliatePopups';

import TextButton from '../components/Buttons/TextButton';
import PrimaryButton from '../components/Buttons/PrimaryButton';

import Text from '../shared/lib/widgets/Text';
import Container from '../shared/lib/widgets/Container';
import RowWidget from '../shared/lib/widgets/RowWidget';


const Affiliate: React.FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [selectedSegment, setSelectedSegment] = useState<number>(0);
    
    const [currentStep, setCurrentStep] = useState<'selectMethod' | 'accountDetails' | 'paypalDetails' | 'confirmWithdrawal' | 'successWithdrawal' | string>('');
    const handleNext = (nextStep: 'accountDetails' | 'paypalDetails' | 'confirmWithdrawal' | 'successWithdrawal') => {
        setCurrentStep(nextStep);
    };

    const handleBack = (prevStep: 'selectMethod' | 'accountDetails' | 'paypalDetails') => {
        setCurrentStep(prevStep);
    };

    return (
        <Container $backgroundColor='#F9FAFC' $padding='32px 0' style={{ minHeight: '92vh' }}>
            {
                currentStep === 'selectMethod' && 
                    <SelectPayoutMethodPopup
                        onClose={() => setCurrentStep('selectMethod')}
                        onSubmit={() => handleNext('accountDetails')}
                    />
            }

            {
                currentStep === 'accountDetails' && 
                    <AccountDetailsPopup
                        onClose={() => setCurrentStep('selectMethod')}
                        onBack={() => handleBack('selectMethod')}
                        onSubmit={() => handleNext('confirmWithdrawal')}
                    />
            }

            {
                currentStep === 'paypalDetails' && 
                    <PayPalDetailsPopup
                        onClose={() => setCurrentStep('selectMethod')}
                        onBack={() => handleBack('selectMethod')}
                        onSubmit={() => handleNext('confirmWithdrawal')}
                    />
            }

            {
                currentStep === 'confirmWithdrawal' && 
                    <ConfirmPayoutWithdrawalPopup
                        onClose={() => setCurrentStep('selectMethod')}
                        onBack={() => handleBack('accountDetails')}
                        onSubmit={() => handleNext('successWithdrawal')}
                    />
            }

            {
                currentStep === 'successWithdrawal' && 
                    <SuccessWithdrawlPopup
                        onClose={() => setCurrentStep('')}
                    />
            }

            <RowWidget $justifyContent='center'>
                <Container $backgroundColor='#FFF' $padding='20px 32px' $borderRadius='12px' $width='1140px'>
                    <RowWidget $justifyContent='space-between' $alignItems='start'>
                        <Container>
                            <Text $fontSize='20px' $color='#201F29' $fontWeight='bold'>{t('affiliate.title')}</Text>
                            
                            <Container $height='10px' />
                            
                            <Text $fontSize='16px' $color='#908F94'>{t('affiliate.subtitle')}</Text>
                        </Container>

                        <TextButton 
                            leading={SupportIcon}
                            title={t('button.support_and_assistance')}
                            color='#7E7D83'
                            onClick={() => navigate('/support')}
                        />
                    </RowWidget>
                    
                    <Container $height='32px' />

                    <TabBarButtons 
                        onClick={setSelectedSegment}
                        value={selectedSegment}
                        items={[t('affiliate.toggle.dashboard'), t('affiliate.toggle.marketing')]}
                    />

                    <Container $height='32px' />

                    <RowWidget $gap='12px'>
                        <InfoCard icon={LinkIcon} title={t('affiliate.info_cards.referris')} subtitle='0' />
                        <InfoCard icon={AddUserIcon} title={t('affiliate.info_cards.sign_ups')} subtitle='0' />
                        <InfoCard icon={CurrencyIcon} title={t('affiliate.info_cards.earning')} subtitle='€0' />
                    </RowWidget>

                    <Container $height='16px' />

                    <RowWidget $gap='16px'>
                        <Container $borderRadius='16px' $border='1px solid #E9E9EA' $width='100%' $padding='20px'>
                            <RowWidget $justifyContent='space-between' $alignItems='center'>
                                <Text $fontWeight='bold' $fontSize='18px'>{t('affiliate.payout_card.title')}</Text>

                                <RowWidget $gap='12px'>
                                    <Container $borderRadius='40px' $border='1px solid #E9E9EA' $padding='6px'>
                                        <RowWidget $gap='8px' $alignItems='center'>
                                            <img 
                                                src={WalletIcon} 
                                                alt="icon" 
                                            />
                                            <Text $color='#7E7D83' $fontSize='14px'>{t('affiliate.payout_card.minimum_payout')}</Text>
                                        </RowWidget>
                                    </Container>

                                    <Container $borderRadius='40px' $border='1px solid #E9E9EA' $padding='6px'>
                                        <RowWidget $gap='8px' $alignItems='center'>
                                            <img 
                                                src={ClockIcon} 
                                                alt="icon" 
                                            />
                                            <Text $color='#7E7D83' $fontSize='14px'>{t('affiliate.payout_card.processing_time')}</Text>
                                        </RowWidget>
                                    </Container>
                                </RowWidget>
                            </RowWidget>

                            <Container $backgroundColor='#E9E9EA' $width='100%' $height='1px' $margin='20px 0' />

                            <Container $backgroundColor='#E6F2FF' $borderRadius='12px' $width='100%' $padding='30px 0'>
                                <Text $textAlign='center' $fontSize='38px' $fontWeight='bold' $color='#007FFF'>$0,00</Text>
                                <Text $textAlign='center' $fontWeight='bold'>Balance</Text>
                            </Container>

                            <Container $height='20px' />

                            <PrimaryButton 
                                onClick={() => setCurrentStep('selectMethod')}
                                leading={PayoutIcon}
                                title={t('affiliate.payout_card.title')}
                                $width='100%'
                                // $isDisabled={true}
                            />
                        </Container>

                        <Container $borderRadius='16px' $border='1px solid #E9E9EA' $width='70%' $padding='20px'>
                            <Text $fontSize='18px' $fontWeight='bold'>{t('affiliate.share.title')}</Text>

                            <Text $color='#908F94' $margin='16px 0'>{t('affiliate.share.subtitle')}</Text>

                            <RowWidget $gap='12px' $alignItems='center'>
                                <Input 
                                    value={`${process.env.REACT_APP_URL}/&ref=affiliateId`}
                                    readOnly={true}
                                />

                                <TextButton 
                                    leading={LinkCopyIcon}
                                    title={t('button.copy_link')}
                                />
                            </RowWidget>

                            <RowWidget $margin='16px 0' $alignItems='center' $gap='8px'>
                                <Container $backgroundColor='#E9E9EA' $width='100%' $height='1px'/>

                                <Text $color='#7E7D83'>{t('affiliate.share.or')}</Text>

                                <Container $backgroundColor='#E9E9EA' $width='100%' $height='1px'/>
                            </RowWidget>

                            <RowWidget $gap='12px' $justifyContent='center'>
                                <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                                <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                                <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                                <Container $height='50px' $width='50px' $borderRadius='8px' $border='1px solid #E9E9EA' $cursor='pointer' />
                            </RowWidget>
                        </Container>
                    </RowWidget>

                    <Container $height='50px' />

                    <Text $fontWeight='bold'>{t('affiliate.referrals.title')}</Text>

                    <Text $margin='16px 0' $color='#908F94'>{t('affiliate.referrals.subtitle')}</Text>

                    <ReferralsTable>
                        <RowWidget $justifyContent='center' $alignItems='center' $height='80px'>
                            <Text $fontSize='18px' $color='grey'>{t('empty_list.record')}</Text>
                        </RowWidget>
                    </ReferralsTable>
                </Container>
            </RowWidget>
        </Container>
    );
};


export default Affiliate;
