import React, { useEffect, useRef } from "react";

import styled from "styled-components";

import Text from "../../shared/lib/widgets/Text";

import CrossIcon from '../../assets/CrossIcon.svg'
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


const PopupWidget = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PopupHeader = styled.div`
    padding: 24px;
    border-bottom: 1px solid rgba(32, 31, 41, 0.12);
`;

const PopupCrosIcon = styled.img`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
    opacity: .7;
    padding: 6px;
    &:hover {
        background-color: #e8ebf0;
    }
    &:active {
        background-color: #dce1e8;
    }
`;

const PopupBody = styled.div`
    padding: 24px;
`;

interface PopupProps {
    width?: string;
    title: string;
    subTitle?: string;
    onClose: () => void;
    children: React.ReactNode;
}

const Popup: React.FC<PopupProps> = (props) => {
    const popupRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        const handlerClick = (e: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
                props.onClose();
            }
        };
    
        document.addEventListener('mousedown', handlerClick);
        return () => {
            document.removeEventListener('mousedown', handlerClick);
        };
    }, [props]);
    
    return (
        <PopupWidget>
            <Container $width={props.width ? props.width : '550px'} $backgroundColor="#FFF" $borderRadius="12px" ref={popupRef}>
                <PopupHeader>
                    <RowWidget $justifyContent="space-between" $alignItems="center">
                        <Text $fontSize="18px" $fontWeight="600">{props.title}</Text>

                        <PopupCrosIcon src={CrossIcon} alt="icon" onClick={props.onClose} />
                    </RowWidget>

                    {
                        props.subTitle &&
                            <Text $color="#908F94" $margin="8px 0 0 0">{props.subTitle}</Text>
                    }
                </PopupHeader>

                <PopupBody>{props.children}</PopupBody>
            </Container>
        </PopupWidget>
    )
}

export default Popup;