import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements } from "@stripe/react-stripe-js";

import AllertIcon from '../../assets/AllertIcon.svg';

import Popup from "./Popup"

import Input from "../Inputs/Input";
import InputCreditCard from "../Inputs/InputCreditCard";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";


interface AddCreditCardPopupProps {
    onSubmit: () => void;
    onClose: () => void;
}

const AddCreditCardPopup: React.FC<AddCreditCardPopupProps> = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();

    const elements = useElements();

    const [email, setEmail] = useState('');
    const [showError, setShowError] = useState('');
    const [isElementsReady, setIsElementsReady] = useState(false);
    
    useEffect(() => {
        if (elements) {
            setIsElementsReady(true);
        }
    }, [elements]);

    useEffect(() => {
        if (!isElementsReady) return;

        const cardNumberElement = elements!.getElement(CardNumberElement);
        const cardExpiryElement = elements!.getElement(CardExpiryElement);
        const cardCvcElement = elements!.getElement(CardCvcElement);

        if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
            toast.error(t('messages.errors.somthing_wrong'));
            return;
        }

        const handleChange = (event: any) => {
            if (event.error)
                setShowError(event.error.message);
            else
                setShowError('');
        };

        cardNumberElement.on('change', handleChange);
        cardExpiryElement.on('change', handleChange);
        cardCvcElement.on('change', handleChange);

        return () => {
            cardNumberElement.off('change', handleChange);
            cardExpiryElement.off('change', handleChange);
            cardCvcElement.off('change', handleChange);
        };
    }, [isElementsReady, elements, t]);

    return (
        <Popup title={t('subscriptions.popup.credit_card.title')} onClose={onClose}>
            {
                showError &&
                    <Container $backgroundColor="#FFEEEE" $padding="12px" $borderRadius="6px" $margin="0 0 16px 0">
                        <RowWidget $gap="12px" $alignItems="center">
                            <img src={AllertIcon} alt="icon" height="18px" />

                            <Text $color="#9C3232">{showError}</Text>
                        </RowWidget>
                    </Container>
            }

            <Input 
                label={t('input.card_name_label')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('input.card_name_label')}
            />

            <RowWidget $margin="16px 0 0 0">
                <InputCreditCard label={t('input.card_number_label')}>
                    <CardNumberElement />
                </InputCreditCard>
                
                <Container $width="150px" $margin="0 16px">
                    <InputCreditCard label={t('input.card_expire_label')}>
                        <CardExpiryElement />
                    </InputCreditCard>
                </Container>

                <Container $width="150px">
                    <InputCreditCard label="CVV">
                        <CardCvcElement />
                    </InputCreditCard>
                </Container>
            </RowWidget>

            <RowWidget $justifyContent="space-between"  $margin="24px 0 0 0" $gap="16px">
                <SecondaryButton 
                    onClick={onClose}
                    title={t('button.cancel')}
                    $borderColor='#E9E9EA'
                    $width="120px"
                />
                <PrimaryButton 
                    onClick={onSubmit}
                    title={t('button.add_credit_card')}
                    $width='180px'
                />
            </RowWidget>
        </Popup>
    );
};

export default AddCreditCardPopup;