import { format } from 'date-fns';


export function formatDate(date: Date) {
    if (!date)
        return '-';

    const dayOfMonth = format(date, 'do');
    const month = format(date, 'MMM');
    const year = format(date, 'yyyy');

    return `${month} ${dayOfMonth}, ${year}`;
}

export function formatDateTime(date?: Date) {
    if (!date)
        return '-';

    const formatDateTimeValue = format(date, 'Pp');

    return formatDateTimeValue
};

export function formatTimestampToDate(timestamp: number) {
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;

}