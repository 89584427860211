import React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";


const ReferralsTableStyle = styled.div`
    background-color: #F6F7F9;
    border-radius: 12px;
    border: 1px solid #E9E9EA;
    min-width: 600px;
    overflow: hidden;
    @media (max-width: 950px) {
        flex: 1;
    }
`;

const ReferralsTableHeaderStyle = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 220px 220px;
    gap: 8px;
    padding: 10px 12px;
    border-bottom: 1px solid #E9E9EA;
`;

const ReferralsTableCellStyle = styled.div`
    color: #7E7D83;
    font-size: 14px;
    font-weight: bold;
`;

const TableContentStyle = styled.div`
    background-color: #FFF;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
`;

interface ReferralsTableProps {
    children: React.ReactNode;
}

const ReferralsTable: React.FC<ReferralsTableProps> = ({ children }) => {
    const { t } = useTranslation();
    
    return (
        <ReferralsTableStyle>
            <ReferralsTableHeaderStyle>
                <ReferralsTableCellStyle>{t('affiliate.referrals.name')}</ReferralsTableCellStyle>
                <ReferralsTableCellStyle>{t('affiliate.referrals.sign_up_date')}</ReferralsTableCellStyle>
                <ReferralsTableCellStyle>{t('affiliate.referrals.status')}</ReferralsTableCellStyle>
            </ReferralsTableHeaderStyle>
            
            <TableContentStyle>
                {children}
            </TableContentStyle>
        </ReferralsTableStyle>
    );
}


export default ReferralsTable;