import { useState } from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import CloudUploadIcon from '../../assets/cloud_upload.svg'
import DownArrowIcon from '../../assets/down_arrow.svg'

import DocumentCard from "../Cards/DocumentCard";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


const BrowseButton = styled.label`
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    color: #4158E3;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    border: none;
    background-color: transparent;
    &:hover {
        color: #2E3E9F;
    }
    &:active {
        color: #28368A;
    }
    &:focus {
        color: #28368A;
    }
`;

const DocumentsTitleStyle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
    user-select: none;
`;


interface DocumentsListProps {
    title: string;
    items: any;
    isOpen?: boolean;
}

const DocumentsList: React.FC<DocumentsListProps> = ({ title, items, isOpen = false }) => {
    const { t } = useTranslation();

    const [isVisible, setIsVisible] = useState(isOpen);

    const [files, setFiles] = useState<File[]>([]);

    const handleFileChange = (e: any) => {
        e.preventDefault();

        const selectedFiles = e.target.files;
        if (selectedFiles && selectedFiles.length > 0) {
            const newFiles = Array.from(selectedFiles);
            setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();

        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles.length > 0) {
            const newFiles = Array.from(droppedFiles);
            setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        }
    };

    const allFiles = [...items, ...files];
    
    return (
        <>
            <DocumentsTitleStyle onClick={() => allFiles.length > 0 && setIsVisible(!isVisible)}>
                <Text $color='#7E7D83' $fontWeight='bold'>{title}</Text>

                {
                    allFiles.length > 0 &&
                        <img 
                            src={DownArrowIcon} 
                            alt='icon' 
                            style={{ 
                                rotate: isVisible ? '0deg' : '180deg' 
                            }}
                        />
                }
            </DocumentsTitleStyle>

            {
                isVisible &&
                <Container $margin='8px'>
                    {
                        title === t('chat.sidebar.documents.uploaded_by_lawyer') &&
                            <Container
                                $border="1px dashed #96CBFF"
                                $padding="20px"
                                $borderRadius="12px"
                                $margin="12px"
                                onDrop={handleDrop}
                                onDragOver={(event) => event.preventDefault()}
                            >
                                <img
                                    src={CloudUploadIcon}
                                    alt="icon"
                                    style={{
                                        margin: '0 auto',
                                        display: 'flex',
                                        width: '40px',
                                    }}
                                />

                                <Container $height="16px" />

                                <Text $textAlign="center" $color="#9FAEC1">{t('empty_list.documents')}</Text>

                                <input
                                    type="file"
                                    hidden
                                    id="browse"
                                    onChange={handleFileChange}
                                    accept=".pdf,.docx,.doc,.rtf,.xlsx,.xls,.txt,.jpeg,.png,.tiff,.xml,.eml,.odt"
                                    multiple
                                />

                                <RowWidget $justifyContent="center">
                                    <BrowseButton htmlFor="browse">{t('button.browse')}</BrowseButton>
                                </RowWidget>
                            </Container>
                    }

                    {
                        allFiles.map((item: any, index: number) =>
                            <DocumentCard 
                                key={index} 
                                item={item} 
                                index={index} 
                                creator={item.creator} 
                                onDelete={() => {
                                    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
                                    toast.success(`${item.name} document deleted`)
                                }}
                            />
                        )
                    }
                </Container>
            }
        </>
    );
}


export default DocumentsList;
