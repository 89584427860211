import React from "react";

import { useTranslation } from "react-i18next";

import PlusIcon from '../../assets/PlusIcon.svg';

import PrimaryButton from "../Buttons/PrimaryButton";

import Popup from "./Popup";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


interface WelcomePopupProps {
    onClose: () => void;
    onSubmit: () => void;
}

const WelcomePopup: React.FC<WelcomePopupProps> = ({ onClose, onSubmit }) => {
    const { t } = useTranslation();
    
    return (
        <Popup title={t('my_cases.popup.welcome.title')} onClose={onClose} width="600px">
            <Container $margin="0 auto">
                <Text $textAlign="center" $fontSize="1.75rem" $fontWeight="bold">{t('my_cases.popup.welcome.heading')}</Text>

                <Container $height="20px" />
                
                <Text $textAlign="center" $fontSize="1.1rem">{t('my_cases.popup.welcome.subtitle')}</Text>

                <Container $height="16px" />

                <Text $textAlign="center" $fontSize=".95rem" $color="#555">{t('my_cases.popup.welcome.description')}</Text>

                <Container $height="20px" />

                <RowWidget $justifyContent="center">
                    <PrimaryButton 
                        onClick={onSubmit}
                        leading={PlusIcon} 
                        title={t('button.welcome_create')}
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
}

export default WelcomePopup;