import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getCases } from "../../entities/Cases/models/slices/CasesSlice";

import Popup from "./Popup";

import Input from "../Inputs/Input";

import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";


interface NameCasePopupProps {
    onClose: () => void;
    onSubmit: (name: string) => void;
}

const NameCasePopup: React.FC<NameCasePopupProps> = ({ onClose, onSubmit }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [name, setName] = useState('')
    const [error, setError] = useState('');
    
    const cases = useAppSelector(getCases.selectAll);
    const [currentCaseCount, setCurrentCaseCount] = useState(cases.length);

    useEffect(() => {
        if (cases.length > currentCaseCount)
            navigate(`/case/${cases[0].id}`);
    }, [cases, currentCaseCount, navigate]);
    
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!name.trim())
            return setError(t('messages.errors.input.empty'));

        onSubmit(name);
        setCurrentCaseCount(cases.length);
    };
    
    return (
        <Popup title={t('my_cases.popup.name_case.title')} onClose={onClose}>
            <RowWidget $flexDirection='column'>
                <Text $margin="0 0 16px 0">{t('my_cases.popup.name_case.subtitle')}</Text>

                <Input 
                    value={name} 
                    placeholder={t('input.case_name_label')} 
                    onChange={(e) => setName(e.target.value)} 
                    label={t('input.case_name_placeholder')} 
                    errorMessage={error}
                />

                <RowWidget $justifyContent="space-between"  $margin="16px 0 0 0" $gap="16px">
                    <SecondaryButton 
                        onClick={onClose}
                        title={t('button.cancel')}
                        $width='120px'
                        $borderColor='#E9E9EA'
                    />
                    <PrimaryButton 
                        onClick={(e: any) => handleSubmit(e)}
                        title={t('button.save')}
                        $width='160px'
                    />
                </RowWidget>
            </RowWidget>
        </Popup>
    );
}

export default NameCasePopup;