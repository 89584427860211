import { useState } from "react";

import { useTranslation } from "react-i18next";

import ContactPopup from "./ContactPopup";

import Input from "../Inputs/Input";
import InputTextArea from "../Inputs/InputTextArea";

import PrimaryButton from "../Buttons/PrimaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";


interface FAQContactPopupProps {
    onSubmit: () => void;
    onClose: () => void;
}

const FAQContactPopup: React.FC<FAQContactPopupProps> = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();
    
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <ContactPopup title={t('academy.popup.contact.title')} onClose={onClose}>
            <RowWidget $flexDirection='column'>
                <Text $fontSize="16px" $fontWeight="400" $color="#908F94" $margin="0 0 32px 0">{t('academy.popup.contact.description')}</Text>

                <Input 
                    label={t('input.user_email_label')}
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    placeholder='email@legalassist.it' 
                />
                
                <Container $height="16px" />
                
                <Input 
                    label={t('input.subject_label')}
                    value={subject} 
                    onChange={(e) => setSubject(e.target.value)} 
                    placeholder={t('input.subject_placeholder')}
                />

                <Container $height="16px" />

                <InputTextArea 
                    label={t('input.description_label')}
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)} 
                    placeholder={t('input.description_placeholder')}
                    rows={5}
                />

                <Container $height="32px" />

                <PrimaryButton 
                    onClick={handleSubmit}
                    title={t('button.send_message')}
                    $width='180px'
                />
            </RowWidget>
        </ContactPopup>
    );
};

export default FAQContactPopup;