import { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';

import { $api } from "../../shared/api/api";

import BillingCard from "../Cards/BillingCard";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";
import Loader from "../../shared/lib/widgets/Loader";


const BillingData = () => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [billingHystory, setBillingHystory] = useState([]);

    useEffect(() => {
        fetchBillingInfo();
    }, []);

    const fetchBillingInfo = async () => {
        setIsLoading(true);
        try {
            const response = await $api.get('/payments');
            setBillingHystory(response.data);
        } catch (_) {
            // toast.info(t('messages.info.service_not_available'));
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return (
            <RowWidget $justifyContent='center' $alignItems='center' $height='30vh' $width='100%'>
                <Loader />
            </RowWidget>
        );
    }
    
    return (
        <Container>
            {
                billingHystory.length === 0 ?
                    <RowWidget $justifyContent='center' $alignItems='center' $height='80px'>
                        <Text $fontSize='18px' $color='grey'>{t('empty_list.record')}</Text>
                    </RowWidget>
                    :
                    billingHystory.map((item: any, index: any) => 
                        <BillingCard
                            key={item.id}
                            billingData={item}
                            invoice={`Fattura #00${billingHystory.length - index} - ${new Date(item.created*1000).toLocaleString('en-US', { month: 'long', year: 'numeric' })}`}
                        />
                    )
            }
        </Container>
    )
}

export default BillingData;