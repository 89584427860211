import { useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";

import { auth } from "../../configs/firebase-config";

import { errorHandler } from "../../shared/utils/errorHandler";

import Input from '../../components/Inputs/Input';

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import TextButton from "../../components/Buttons/TextButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";
import { isEmail } from "../../shared/utils/validators";


const ForgotPassword = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);
    
    const [emailError, setEmailError] = useState('');

    const handleForgotPassowrd = (e: React.FormEvent) => {
        e.preventDefault();

        if (!isEmail(email))
            return setEmailError(t('messages.errors.input.email'))

        sendPasswordResetEmail(auth, email)
            .then(() => {
                toast.success(t('messages.success.forgot_password_email_send'));
                setIsEmailSent(true);
            })
            .catch((e: any) => toast.error(errorHandler(e.message)));
    }

    if (isEmailSent) {
        return <CheckEmail email={email} />
    }
    
    return (
        <RowWidget $width="100%" $height="95vh" $justifyContent="center" $alignItems="center">
            <RowWidget $height="100%" $width="360px" $flexDirection="column" $justifyContent="space-between">
                <Container $height="100%" $margin="64px 0 0 0">
                    <RowWidget $justifyContent="center">
                        <img style={{ height: '32px', marginRight: '8px', }} src="images/img_group.svg" alt="logo" />
                        <img style={{ height: '32px' }} src="images/img_group_light_blue_a700.svg" alt="logo" />
                    </RowWidget>

                    <Container $height="64px" />

                    <RowWidget $justifyContent="center">
                        <img src="images/img_close.svg" alt="key" style={{ width: '80px', height: '80px' }} />
                    </RowWidget>

                    <Container $height="16px" />

                    <Text $fontWeight="bold" $fontSize="24px" $textAlign="center">{t('auth.forgot_password.title')}</Text>

                    <Container $height="8px" />

                    <Text $color="#908F94" $textAlign="center">{t('auth.forgot_password.description')}</Text>

                    <Container $height="32px" />

                    <Input 
                        label={t('input.user_email_label')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="email@gmail.com"
                        errorMessage={emailError}
                    />

                    <Container $height="32px" />

                    <PrimaryButton 
                        onClick={handleForgotPassowrd}
                        title={t('button.send')}
                        $width="100%"
                    />

                    <Container $height="8px" />
                    
                    <SecondaryButton 
                        onClick={() => navigate('/sign-in')}
                        title={t('button.back_to_login')}
                        $borderColor="#E9E9EA"
                    />
                </Container>
                
                <Text $textAlign="center" $color="#7E7D83" $fontSize="14px">Copyright © 2024 LegalAssists</Text>
            </RowWidget>
        </RowWidget>
    );
}

export default ForgotPassword;

const CheckEmail = (props: any) => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    
    const resendEmail = (e: React.FormEvent) => {
        e.preventDefault();

        setIsLoading(true);

        sendPasswordResetEmail(auth, props.email)
            .then(() => {
                toast.success(t('messages.success.forgot_password_email_send'));
            })
            .catch((e: any) => toast.error(errorHandler(e.message)))
            .finally(() => setIsLoading(false));
    }

    return (
        <RowWidget $width="100%" $height="95vh" $justifyContent="center" $alignItems="center">
            <RowWidget $height="100%" $width="360px" $flexDirection="column" $justifyContent="space-between">
                <Container $height="100%" $margin="64px 0 0 0">
                    <RowWidget $justifyContent="center">
                        <img style={{ height: '32px', marginRight: '8px', }} src="images/img_group.svg" alt="logo" />
                        <img style={{ height: '32px' }} src="images/img_group_light_blue_a700.svg" alt="logo" />
                    </RowWidget>

                    <Container $height="64px" />

                    <RowWidget $justifyContent="center">
                        <img src="images/check_email.svg" alt="key" style={{ width: '80px', height: '80px' }} />
                    </RowWidget>

                    <Container $height="16px" />

                    <Text $fontWeight="bold" $fontSize="24px" $textAlign="center">{t('auth.forgot_password.success.title')}</Text>

                    <Container $height="8px" />

                    <Text $color="#908F94" $textAlign="center">{t('auth.forgot_password.success.description')}</Text>

                    <Container $height="6px" />

                    <Text $color="#007fff" $textAlign="center">{props.email ? props.email : '-'}</Text>

                    <Container $height="32px" />

                    <PrimaryButton 
                        onClick={() => navigate('/sign-in')}
                        title={t("button.back_to_login")}
                        $width="100%"
                    />

                    <Container $height="16px" />

                    <RowWidget $justifyContent="center">
                        <Text $margin="0 4px" $fontSize="14px" $color="#46454D">{t('auth.forgot_password.success.email_no_receive')}</Text>
                        <TextButton 
                            onClick={(e) => !isLoading && resendEmail(e)}
                            title={t('button.send_again')}
                            fontWeight="bold" 
                            fontSize="14px" 
                            color="#007FFF" 
                        />
                    </RowWidget>
                </Container>
                
                <Text $textAlign="center" $color="#7E7D83" $fontSize="14px">Copyright © 2024 LegalAssists</Text>
            </RowWidget>
        </RowWidget>
    );
}
