import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ArrowDown from '../../assets/DropdownIcon.svg';
import CheckedIcon from '../../assets/RightMarkIcon.svg';

import RowWidget from "../../shared/lib/widgets/RowWidget";
import Text from "../../shared/lib/widgets/Text";


const DropDownStyle = styled.div<{ $isOpen: boolean, $width?: string }>`
    user-select: none;
    width: ${(props) => props.$width ?? '100%'};
    min-width: ${(props) => props.$width ?? ''};
    position: relative;

    @media (max-width: 532px) {
        flex: 1 1 100%;
        width: 100%;
    }
`;

const DropDownContent = styled.button<{ $border?: string, $backgroundColor?: string }>`
    height: 45px;
    width: 100%;
    padding: ${(props) => props.$border ? '' : '0 12px'};
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.$backgroundColor ? props.$backgroundColor : '#FFF'};
    outline: none;
    border: 1px solid ${(props) => props.$border || props.$backgroundColor || '#E9E9EA'};
    border-radius: 6px;
    &:focus {
        border: 1px solid ${(props) => props.$border ?? '#4158E3'};
    }
    &:hover {
        border: 1px solid ${(props) => props.$border ?? '#4158E3'};
    }
`;

const DropDownLeadingIcon = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 8px;
    transition: .3s;
`;

const DropDownTrailingIcon = styled.img<{ $isOpen: boolean }>`
    width: 10px;
    height: 10px;
    margin-left: 24px;
    transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: .3s;
`;

const DropDownMenu = styled.div<{ $postionTop?: boolean }>`
    background-color: #FFF;
    position: absolute;
    top: ${(props) => props.$postionTop ? '' : 'calc(100% + 4px)'};
    bottom: ${(props) => props.$postionTop ? 'calc(100% + 4px)' : ''};
    left: 0;
    right: 0;
    padding: 2px;
    border: 1px solid #E9E9EA;
    max-height: 170px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 40px;
        background: #E8ECF0; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #aaa; 
    }
`;

const DropDownItem = styled.div<{ $isActive: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: ${(props) => props.$isActive ? '#F6F7F9' : 'transparent' };
    cursor: pointer;
    border-radius: 6px;
    &:hover {
        background-color: #F6F7F9;
    }
`;

interface DropDownProps {
    width?: string;
    label?: string;
    leading?: string;
    menuItems: string[];
    positionTop?: boolean;
    border?: string;
    dropDownName?: string;
    translation?: string;
    backgroundColor?: string;
    value: string;
    onChange: (e: string) => void;
}

const DropDown: React.FC<DropDownProps> = ({ width, label, leading, menuItems, positionTop, border, dropDownName, translation, backgroundColor, value, onChange }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const dropDownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handlerClick = (e: MouseEvent) => {
            if (dropDownRef.current && !dropDownRef.current.contains(e.target as Node)) {
                setIsOpen(false);
            }
        };
    
        document.addEventListener('mousedown', handlerClick);
        return () => {
            document.removeEventListener('mousedown', handlerClick);
        };
    }, []);

    return (
        <DropDownStyle ref={dropDownRef} $isOpen={isOpen} $width={width}>
            {
                label &&
                    <Text $fontSize="14px" $color="#46454D" $margin="0 0 6px 0">{label}</Text>
            }

            <DropDownContent $border={border} $backgroundColor={backgroundColor} onClick={() => setIsOpen(!isOpen)}>
                <RowWidget $alignItems="center">
                    {
                        leading &&
                        <DropDownLeadingIcon src={leading} alt="icon" />
                    }
                    <Text>{dropDownName || value || '-'}</Text>
                </RowWidget>

                <DropDownTrailingIcon $isOpen={isOpen} src={ArrowDown} alt="icon" />
            </DropDownContent>

            {
                isOpen ?
                    menuItems.length === 0 ?
                        <DropDownMenu $postionTop={positionTop}>
                            <Text $textAlign="center" $padding="8px">{t('empty_list.record')}</Text>
                        </DropDownMenu>
                        :
                        <DropDownMenu $postionTop={positionTop}>
                            {
                                menuItems.map((item, index) =>
                                    <DropDownItem 
                                        key={index} 
                                        onClick={() => {
                                            onChange(item);
                                            setIsOpen(false);
                                        }} 
                                        $isActive={value === item}
                                    >
                                        {
                                            translation ?
                                                <Text>{t(`dropdown.${translation}.${item}`)}</Text>
                                                :
                                                <Text>{item}</Text>
                                        }
                                        {
                                            value === item &&
                                                <img src={CheckedIcon} alt="icon" width="12px" height="12px" />
                                        }
                                    </DropDownItem>
                                )
                            }
                        </DropDownMenu>
                    :
                    null
            }
        </DropDownStyle>
    );
}

export default DropDown;