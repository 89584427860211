import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { updateUserData } from '../entities/User/models/services/user.services';

import { StateSchema } from '../app/providers/StoreProvider/config/StateSchema';

// import { $api } from '../shared/api/api';
import { subscriptionPlans } from '../shared/consts/default_values';
import { useAppSelector } from '../shared/components/useAppSelector/useAppSelector';
import { useAppDispatch } from '../shared/components/useAppDispatch/useAppDispatch';

import SubscribePopup from '../components/Popups/SubscribePopup';
import SubscriptionCard from '../components/Cards/SubscriptionCard';
import SalesContactPopup from '../components/Popups/SalesContactPopup';

import Text from '../shared/lib/widgets/Text';
import Container from '../shared/lib/widgets/Container';
import RowWidget from '../shared/lib/widgets/RowWidget';
import SaleToggle from '../components/Toggles/SaleToggle';
import SuccessPopup from '../components/Popups/SuccessPopup';


const Subscriptions: React.FC = () => {
    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();
    
    const [selectedSubscription, setSelectedSubscription] = useState<boolean>(false);
    const [showContactUs, setShowContactUs] = useState(false);
    const [showSubscribe, setShowSubscribe] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);

    const user = useAppSelector((state: StateSchema) => state.user.userData);
    const handleSubscribeSubmit = async () => {
        setShowSubscribe(false);
        dispatch(updateUserData({ id: user?.id, subscription: { id: selectPlan.id } }))
        setShowCheckout(true);
    };

    const handleContactUsSubmit = (formData: any) => {
        toast.info(t('messages.info.service_not_available'));
        // $api.post('/user-email', formData)
        // .then(() => toast.success("Email successfully sended!"))
        // .catch(() => toast.error("Email sent error! Please try again."))
        // setShowContactUs(false);
    };

    const [selectPlan, setSelectPlan] = useState<any>();

    const handleUpgradePlan = (title: string) => {
        if (title === 'ENTERPRISE')
            setShowContactUs(true);
        else
            setShowSubscribe(true);
    }
    
    const subscriptions = subscriptionPlans(user?.subscription_id?.id);

    return (
        <RowWidget $alignItems='center' $flexDirection='column'>
                {
                    showContactUs && 
                    <SalesContactPopup 
                        onSubmit={handleContactUsSubmit} 
                        onClose={() => setShowContactUs(false)}
                    />
                }

                {
                    showSubscribe && 
                    <SubscribePopup 
                        subscriptionDetails={selectPlan} 
                        onSubmit={handleSubscribeSubmit} 
                        onClose={() => setShowSubscribe(false)}
                    />
                }

                {
                    showCheckout &&
                    <SuccessPopup 
                        onClose={() => setShowCheckout(false)}
                    />
                }

                <Container $height='50px' />

                <Text $fontSize='32px' $fontWeight='bold'>{t('upgrade_plan.title')}</Text>

                <Container $height='8px' />

                <Text $color="#908F94" $fontWeight='400'>{t('upgrade_plan.subtitle')}</Text>

                <Container $height='50px' />
                
                <SaleToggle onClick={setSelectedSubscription} toggleState={selectedSubscription} />

                <RowWidget $gap='16px' $flexWrap='wrap' $justifyContent='center' $margin='50px 0'>
                    {
                        subscriptions.map((item, index) => 
                        <SubscriptionCard
                            key={index}
                            subscriptionDetails={item}
                            toggle={!selectedSubscription}
                            onClick={() => {
                                setSelectPlan(item);
                                handleUpgradePlan(item.title);
                            }} 
                        />
                    )}
                </RowWidget>
        </RowWidget >
    );
};

export default Subscriptions;

