import React, { useState } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import DocumentIcon from '../../assets/document_icon.svg'
import AIDocumentIcon from '../../assets/ai_document_icon.svg'

import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";

import { formatTimestampToDate } from "../../shared/utils/date_format";
import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";

import DocumentPopup from "../Popups/DocumentPopup";

import DocumentActionMenu from "../DropDown/DocumentActionsDropdown";

import Text from "../../shared/lib/widgets/Text";


const DocumentsContainerStyle = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 12px;
    cursor: pointer;
    border-radius: 8px;
    background-color: transparent;
    &:hover {
        background-color: #E6F2FF;
    }
`;

const DocumentsDocHeaderStyle = styled.div`
    display: flex;
    gap: 12px;
    overflow: hidden;
`;

const DocumentsDocTitleStyle = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;


interface DocumentCardProps {
    item: File;
    index: number;
    creator: string;
    onDelete: () => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ item, index, creator, onDelete }) => {
    const navigate = useNavigate();

    const user = useAppSelector((state: StateSchema) => state.user.userData);
    
    const [showDocumentPreview, setShowDocumentPreview] = useState(false);

    const handleDownload = (file: any) => {
        if (file) {
            const link = document.createElement('a');
            link.href = file.url;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('No file data available for download');
        }
    };

    return (
        <DocumentsContainerStyle key={index} onClick={() => setShowDocumentPreview(true)}>
            {
                showDocumentPreview &&
                    <DocumentPopup onClose={() => setShowDocumentPreview(false)} file={item} index={index + 1} />
            }

            <DocumentsDocHeaderStyle>
                <img
                    src={creator === 'AI' ? AIDocumentIcon : DocumentIcon}
                    alt="icon"
                    width={creator === 'AI' ? '22px' : '20px'}
                    height={creator === 'AI' ? '22px' : '24px'}
                />

                <DocumentsDocTitleStyle>
                    <Text $margin='0 0 4px 0' $fontWeight='bold' $overflow='hidden' $textOverflow='ellipsis'>{item.name}</Text>

                    <Text $fontSize='14px' $color='#A4A3A7'>{creator === 'AI' ? 'AI' : `${user?.first_name} ${user?.last_name}`} | {formatTimestampToDate(item.lastModified)}</Text>
                </DocumentsDocTitleStyle>
            </DocumentsDocHeaderStyle>

            <DocumentActionMenu 
                onEdit={() => navigate('/document-edit', { state: { file: item } })} 
                onDownload={() => handleDownload(item)} 
                onDelete={onDelete}
                item={item}
            />
        </DocumentsContainerStyle>
    );
}

export default DocumentCard