import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import styled from "styled-components";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import User from '../../../assets/user.svg';

import { StateSchema } from "../../../app/providers/StoreProvider/config/StateSchema";

import { auth } from "../../../configs/firebase-config";

import { $api } from "../../../shared/api/api";
import { USER_LOCALSTORAGE_KEY } from "../../../shared/consts/localStorage";

import { userActions } from "../../../entities/User/models/slices/UserSlice";
import { casesActions } from "../../../entities/Cases/models/slices/CasesSlice";
import { updateUserData } from "../../../entities/User/models/services/user.services";

import { isEmail } from "../../../shared/utils/validators";
import { useAppSelector } from "../../../shared/components/useAppSelector/useAppSelector";
import { useAppDispatch } from "../../../shared/components/useAppDispatch/useAppDispatch";

import SecondaryButton from "../../Buttons/SecondaryButton";
import PrimaryButton from "../../Buttons/PrimaryButton";

import Input from "../../Inputs/Input";
import InputPhone from "../../Inputs/InputPhone";
import DeleteAccountPopup from "../../Popups/DeleteAccountPopup";

import TextButton from "../../Buttons/TextButton";

import RowWidget from "../../../shared/lib/widgets/RowWidget";
import Container from "../../../shared/lib/widgets/Container";
import Text from "../../../shared/lib/widgets/Text";


const PersonalInfoStyle = styled.div`
    width: 572px;
    @media (max-width: 645px) {
        width: 100%;
    }
`;

const HiddenInput = styled.input`
    display: none;
`;

const PersonalInfo: React.FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const userState = useAppSelector((state: StateSchema) => state.user);
    const user = userState.userData;

    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    });
    
    const [inputName, setInputName] = useState<string>('');
    const [inputSurname, setInputSurname] = useState<string>('');
    const [inputEmail, setInputEmail] = useState<string>('');
    const [inputPhone, setInputPhone] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');

    useEffect(() => {
        setInputName(user?.first_name||'')
        setInputSurname(user?.last_name||'')
        setInputEmail(user?.email||'')
        setInputPhone(user?.phone||'')
        setProfileImage(user?.image||'')
    }, [user]);
    
    const [showDeletingAccount, setDeletingAccount] = useState(false);

    const handleUploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setProfileImage(imageUrl);
            dispatch(updateUserData({ id: user?.id, image: profileImage }));
            toast.success(t('messages.success.image_upload'));
        }
    };
    
    const validate = () => {
        const newErrors = {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
        };

        if (!inputName) 
            newErrors.firstName = t('messages.errors.input.empty');

        if (!inputSurname) 
            newErrors.lastName = t('messages.errors.input.empty');

        if (inputPhone.length < 5) 
            newErrors.phone = t('messages.errors.input.not_complete');

        if (!isEmail(inputEmail))
            newErrors.email = t('messages.errors.input.email');

        return newErrors;
    };

    const handleUpdate = () => {
        const validationErrors = validate();
        if (Object.values(validationErrors).some(error => error))
            return setErrors(validationErrors);

        dispatch(updateUserData({ id: user?.id, first_name: inputName, last_name: inputSurname, email: inputEmail, phone: inputPhone }));
        toast.success(t('messages.success.user_data_updated'));
    }

    const handlerDeleteAccount = async () => {
        try {
            $api.delete(`/users/${user?.id}/`);

            await auth.currentUser!.getIdToken(true);
            await auth.currentUser!.delete();

            navigate('/sign-in');
            localStorage.removeItem(USER_LOCALSTORAGE_KEY);
            localStorage.removeItem('accessToken');
            
            dispatch(userActions.logout());
            dispatch(casesActions.logout());
            signOut(auth);
        } catch (_) {
            toast.error(t('messages.errors.somthing_wrong'));
        }
    }

    return (
        <PersonalInfoStyle>
            {
                showDeletingAccount && 
                <DeleteAccountPopup 
                    onSubmit={handlerDeleteAccount}
                    onClose={() => setDeletingAccount(false)}
                />
            }

            <RowWidget $alignItems='center' $justifyContent='space-between' $flexWrap="wrap" $gap="16px">
                <RowWidget $alignItems='center' $gap="16px">
                    <img 
                        src={profileImage ? profileImage : User} 
                        alt="user"
                        style={{
                            width: '60px', 
                            height: '60px', 
                            borderRadius: '50%',
                            objectFit: 'cover',
                        }}
                    />

                    <Text $fontWeight="bold" $fontSize="18px">{t('settings.personal_info.profile_img')}</Text>
                </RowWidget>
                
                {
                    profileImage && 
                    <SecondaryButton 
                        onClick={() => setProfileImage('')}
                        title={t('button.remove')}
                        $borderColor='#E9E9EA'
                        $width="120px"
                    />
                }
                
                <SecondaryButton 
                    onClick={() => document.getElementById('photo-upload')?.click()}
                    title={profileImage ? t('button.change_img') : t('button.upload_img')}
                    $width="120px"
                />
                <HiddenInput
                    type="file"
                    id="photo-upload"
                    onChange={(e: any) => handleUploadPhoto(e)}
                    accept="image/*"
                />
            </RowWidget>

            <Container $height="32px" />
            
            <RowWidget $gap="16px">
                <Input
                    value={inputName} 
                    onChange={(e) => setInputName(e.target.value)} 
                    label={t('input.user_name_label')}
                    placeholder={user?.first_name}
                    errorMessage={errors.firstName}
                />

                <Input
                    value={inputSurname} 
                    onChange={(e) => setInputSurname(e.target.value)} 
                    label={t('input.user_surname_label')}
                    placeholder={user?.last_name}
                    errorMessage={errors.lastName}
                />
            </RowWidget >

            <Container $height="16px" />

            <RowWidget $gap="16px">
                <Input 
                    type="email"
                    value={inputEmail} 
                    onChange={(e) => setInputEmail(e.target.value)} 
                    label={t('input.user_email_label')} placeholder={user?.email} 
                    errorMessage={errors.email}
                />

                <InputPhone 
                    value={inputPhone} 
                    onChange={(e: any) => setInputPhone(e)} 
                    placeholder={user?.phone && user?.phone.toString()} 
                    errorMessage={errors.phone}
                />
            </RowWidget>

            <Container $height="32px" />
            
            <Container $width="100%" $height="1px" $backgroundColor="#DCE1E8" />

            <Container $height="32px" />

            <RowWidget $alignItems='center' $justifyContent='space-between' $gap="16px">
                <TextButton 
                    type="red"
                    fontSize="14px"
                    onClick={() => setDeletingAccount(true)}
                    title={t('button.delete_account')}
                />

                <PrimaryButton 
                    onClick={handleUpdate} 
                    title={t('button.update')}
                    $width="120px"
                />
            </RowWidget>
        </PersonalInfoStyle>
    )
}
export default PersonalInfo;