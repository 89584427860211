import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import CompanyForm from "../Forms/CompanyForm";
import FreelancerForm from "../Forms/FreelancerForm";
import IndividualForm from "../Forms/IndividualForm";
import PhysicalPersonForm from "../Forms/PhysicalPersonForm";
import FirmOrAssociationForm from "../Forms/FirmOrAssociationForm";

import Popup from "./Popup"

import DropDown from "../DropDown/DropDown";

import Container from "../../shared/lib/widgets/Container";


interface EditBillingInfoPopupProps {
    onSubmit: () => void;
    onClose: () => void;
}

const EditBillingInfoPopup: React.FC<EditBillingInfoPopupProps> = ({ onClose }) => {
    const { t } = useTranslation();
    
    const [selectedType, setSelectedType] = useState(t('dropdown.legal_entity.title'));
    
    const formComponents: any = {
        [t('dropdown.legal_entity.freelancer')]: FreelancerForm,
        [t('dropdown.legal_entity.individual_enterprise')]: IndividualForm,
        [t('dropdown.legal_entity.company')]: CompanyForm,
        [t('dropdown.legal_entity.law_firm_or_professional_association')]: FirmOrAssociationForm,
        [t('dropdown.legal_entity.physical_person')]: PhysicalPersonForm,
    };

    const SelectedForm = formComponents[selectedType];

    return (
        <Popup title={t('settings.billing_and_payments.popup.title')} onClose={onClose} width="650px">
            <Container>
                <DropDown 
                    label={t('dropdown.legal_entity.label')}
                    dropDownName={selectedType}
                    value={selectedType} 
                    onChange={setSelectedType} 
                    menuItems={Object.keys(formComponents)} 
                    backgroundColor="#F6F7F9"
                />

                {SelectedForm && <SelectedForm onClose={onClose} />}
            </Container>
        </Popup>
    );
};

export default EditBillingInfoPopup;
