import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import ArrowDown from '../../assets/DropdownIcon.svg';
import CheckedIcon from '../../assets/RightMarkIcon.svg';

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";


const DropDownStyle = styled.div<{ $isOpen: boolean, $width?: string }>`
    width: ${(props) => props.$width ?? '100%'};
    min-width: ${(props) => props.$width ?? ''};
    height: 45px;
    background-color: #FFF;
    border-radius: 6px;
    position: relative;

    @media (max-width: 532px) {
        flex: 1 1 100%;
        width: 100%;
    }
`;

const DropDownContent = styled.button<{ $border?: string }>`
    height: 100%;
    width: 100%;
    padding: ${(props) => props.$border ? '' : '0 12px'};
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    outline: none;
    border: 1px solid ${(props) => props.$border ?? '#E9E9EA'};
    border-radius: 6px;
    &:focus {
        border: 1px solid ${(props) => props.$border ?? '#4158E3'};
    }
    &:hover {
        border: 1px solid ${(props) => props.$border ?? '#4158E3'};
    }
`;

const DropDownLeadingIcon = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 8px;
    transition: .3s;
`;

const DropDownTrailingIcon = styled.img<{ $isOpen: boolean }>`
    width: 10px;
    height: 10px;
    margin-left: 24px;
    transform: ${(props) => (props.$isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: .3s;
`;

const DropDownMenu = styled.div<{ $postionTop?: boolean }>`
    background-color: #FFF;
    position: absolute;
    top: ${(props) => props.$postionTop ? '' : 'calc(100% + 4px)'};
    bottom: ${(props) => props.$postionTop ? 'calc(100% + 4px)' : ''};
    left: 0;
    right: 0;
    padding: 2px;
    border: 1px solid #E9E9EA;
    max-height: 250px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 40px;
        background: #E8ECF0; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #aaa; 
    }
`;

const DropDownItem = styled.div<{ $isActive: boolean }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: ${(props) => props.$isActive ? '#F6F7F9' : 'transparent' };
    cursor: pointer;
    border-radius: 6px;
    &:hover {
        background-color: #F6F7F9;
    }
`;

interface SortByDropdownProps {
    width?: string;
    leading?: string;
    title1: string;
    title2: string;
    menuItems1: string[];
    menuItems2: string[];
    positionTop?: boolean;
    border?: string;
    dropDownName?: string;
    translation?: string;
    value: string;
    onChange: (e: string) => void;
}

const SortByDropdown: React.FC<SortByDropdownProps> = ({ width, leading, title1, title2, menuItems1, menuItems2, positionTop, border, dropDownName, translation, value, onChange }) => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const dropDownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handlerClick = (e: MouseEvent) => {
            if (dropDownRef.current && !dropDownRef.current.contains(e.target as Node)) {
                setIsOpen(false);
            }
        };
    
        document.addEventListener('mousedown', handlerClick);
        return () => {
            document.removeEventListener('mousedown', handlerClick);
        };
    }, []);

    return (
        <DropDownStyle ref={dropDownRef} $isOpen={isOpen} $width={width} onClick={() => setIsOpen(!isOpen)}>
            <DropDownContent $border={border}>
                <RowWidget $alignItems="center">
                    {
                        leading &&
                        <DropDownLeadingIcon src={leading} alt="icon" />
                    }
                    <Text>{dropDownName ?? '-'}</Text>
                </RowWidget>

                <DropDownTrailingIcon $isOpen={isOpen} src={ArrowDown} alt="icon" />
            </DropDownContent>

            {
                isOpen &&
                <DropDownMenu $postionTop={positionTop}>
                    <Text $color="#7E7D83" $padding="12px 12px 4px 12px" $fontSize="14px">{title1}</Text>
                    
                    {
                        menuItems1.map((item, index) =>
                            <DropDownItem key={index} onClick={() => onChange(item)} $isActive={value === item}>
                                {
                                    translation ?
                                        <Text>{t(`dropdown.${translation}.${item}`)}</Text>
                                        :
                                        <Text>{item}</Text>
                                }
                                {
                                    value === item &&
                                        <img src={CheckedIcon} alt="icon" width="12px" height="12px" />
                                }
                            </DropDownItem>
                        )
                    }

                    <Text $color="#7E7D83" $padding="12px 12px 4px 12px" $fontSize="14px">{title2}</Text>

                    {
                        menuItems2.map((item, index) =>
                            <DropDownItem key={index} onClick={() => onChange(item)} $isActive={value === item}>
                                {
                                    translation ?
                                        <Text>{t(`dropdown.${translation}.${item}`)}</Text>
                                        :
                                        <Text>{item}</Text>
                                }
                                {
                                    value === item &&
                                        <img src={CheckedIcon} alt="icon" width="12px" height="12px" />
                                }
                            </DropDownItem>
                        )
                    }
                </DropDownMenu>
            }
        </DropDownStyle>
    );
}

export default SortByDropdown;