import React from 'react';

import CheckedIcon from '../../assets/RightMarkIcon.svg';

import Container from '../../shared/lib/widgets/Container';
import RowWidget from '../../shared/lib/widgets/RowWidget';
import Text from '../../shared/lib/widgets/Text';


interface UserRoleToggleProps {
    onClick: (toggleState: boolean) => void;
    toggleState: boolean;
}

const UserRoleToggle: React.FC<UserRoleToggleProps> = ({ onClick, toggleState }) => {
    return (
        <RowWidget>
            <Container onClick={() => onClick(false)} $cursor='pointer' $width='100%' $border={`1px solid ${!toggleState ? '#4158E3' : 'transparent'}`} $backgroundColor={!toggleState ? 'transparent' : '#E9E9EA'} $borderRadius='16px' $padding='16px'>
                <RowWidget $justifyContent='space-between'>
                    <Text $fontSize='16px' $fontWeight='bold' color={toggleState ? '#7E7D83' : '#000'}>Utente</Text>
                    {
                        !toggleState &&
                        <img src={CheckedIcon} alt='icon' height="15px" width="15px" />
                    }
                </RowWidget>

                <Container $height='10px' />
                
                <Text $textAlign='left' $fontSize='14px' $fontWeight='400' $color='#7E7D83'>Può creare e lavorare con casi legali, ma non può gestire team e indirizzi di fatturazione.</Text>
            </Container>

            <Container $width='16px' />

            <Container onClick={() => onClick(true)} $cursor='pointer' $width='100%' $border={`1px solid ${toggleState ? '#4158E3' : 'transparent'}`} $backgroundColor={toggleState ? 'transparent' : '#E9E9EA'} $borderRadius='16px' $padding='16px'>
                <RowWidget $justifyContent='space-between'>
                    <Text $fontSize='16px' $fontWeight='bold' color={!toggleState ? '#7E7D83' : '#000'}>Admin</Text>
                    {
                        toggleState &&
                        <img src={CheckedIcon} alt='icon' height="15px" width="15px" />
                    }
                </RowWidget>

                <Container $height='10px' />
                
                <Text $textAlign='left' $fontSize='14px' $fontWeight='400' $color='#7E7D83'>Può aggiungere e rimuovere utenti e gestire gli indirizzi di fatturazione.</Text>
            </Container>
        </RowWidget>
    );
};
export default UserRoleToggle;


