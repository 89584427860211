import { useState } from "react";

import styled from "styled-components";

import CheckIcon from '../../assets/RightMarkIcon.svg';

import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";
import Text from "../../shared/lib/widgets/Text";


const PayloadUl = styled.ul`
    margin: 16px 0 0 16px;
    font-size: 14px;
    color: #7E7D83;
`;

const PayoutToggle = () => {
    const [toggle, setToggle] = useState(true);
    
    return (
        <RowWidget $gap='12px'>
            <Container $cursor='pointer' $borderRadius='16px' $border={`1px solid ${toggle ? '#4158E3' : '#E9E9EA'}`} $padding='16px' $width='100%' onClick={() => setToggle(true)}>
                <RowWidget $justifyContent='space-between' $alignItems='center'>
                    <Text $fontWeight='bold'>Bank Transfer</Text>

                    {
                        toggle &&
                            <img src={CheckIcon} alt="icon" width='15px' height='15px' />
                    }
                </RowWidget>

                <PayloadUl>
                    <li>Get paid in 5-7 bussiness days</li>
                    <li>No fees</li>
                </PayloadUl>
                {/* <RowWidget $justifyContent='space-between' $alignItems='end' $margin='16px 0 0 0'>
                    <Container>
                        <Text $color='#7E7D83' $fontSize='14px'>Name Surname</Text>
                        <Text $color='#7E7D83' $fontSize='14px'>Account ending in ****1234</Text>
                    </Container>

                    <TextButton 
                        title='Change'
                        $fontSize='14px'
                        $fontWeight='bold'
                    />
                </RowWidget> */}
            </Container>

            <Container $cursor='pointer' $borderRadius='16px' $border={`1px solid ${!toggle ? '#4158E3' : '#E9E9EA'}`} $padding='16px' $width='100%' onClick={() => setToggle(false)}>
                <RowWidget $justifyContent='space-between' $alignItems='center'>
                    <Text $fontWeight='bold'>PayPal</Text>

                    {
                        !toggle &&
                            <img src={CheckIcon} alt="icon" width='15px' height='15px' />
                    }
                </RowWidget>

                <PayloadUl>
                    <li>Get paid in 5-7 bussiness days</li>
                    <li>No fees</li>
                </PayloadUl>
                {/* <RowWidget $justifyContent='space-between' $alignItems='end' $margin='16px 0 0 0'>
                    <Text $color='#7E7D83' $fontSize='14px'>user@gmail.com</Text>

                    <TextButton 
                        title='Change'
                        $fontSize='14px'
                        $fontWeight='bold'
                    />
                </RowWidget> */}
            </Container>
        </RowWidget>
    );
}


export default PayoutToggle;