import React from "react";

import styled from "styled-components";

import InputComponent from "./InputComponent";


const TextAreaStyle = styled.textarea<{ $backgroundColor?: string, $padding?: string }>`
    width: 100%;
    resize: none;
    padding: ${(props) => props.$padding ?? '10px 16px'};
    border-radius: 8px;
    border: 1px solid transparent;
    font-family: 'SF Pro Display', sans-serif;
    background-color: ${(props) => props.$backgroundColor ? props.$backgroundColor : '#f6f7f9'};
    font-size: 16px;
    &:focus{
        border: 1px solid ${(props) => props.$backgroundColor ? 'transparent' : '#4158E3'};
        outline: none;
    }
    &:hover {
        border: 1px solid ${(props) => props.$backgroundColor ? 'transparent' : '#4158E3'};
    }
    &::placeholder {
        color: #A4A3A7;
    }
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 40px;
        background: #E8ECF0; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #aaa; 
    }
`;

interface InputTextAreaProps {
    name?: string;
    label?: string;
    rows?: number;
    disabled?: boolean;
    placeholder?: string;
    value?: string;
    onChange?: (e: any) => void;
    onSubmit?: (e: any) => void;

    backcgroundColor?: string;
    padding?: string;
}

const InputTextArea: React.FC<InputTextAreaProps> = (props) => {
    return (
        <InputComponent label={props.label}>
            <TextAreaStyle 
                disabled={props.disabled}
                $padding={props.padding}
                $backgroundColor={props.backcgroundColor}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                cols={1}
                rows={props.rows ? props.rows : 3}
                onKeyDown={(e) => {
                    if (props.onSubmit)
                        if (e.key === "Enter" && !e.shiftKey && "form" in e.target) {
                            e.preventDefault();
                            props.onSubmit(e);
                        }
                }}
            />
        </InputComponent>
    );
}

export default InputTextArea;