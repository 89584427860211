import React, { useEffect, useRef } from "react";

import styled from "styled-components";

import CrossIcon from '../../assets/CrossIcon.svg'

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


const PopupWidget = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PopupCrosIcon = styled.img`
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
    opacity: .7;
    padding: 6px;
    &:hover {
        background-color: #e8ebf0;
    }
    &:active {
        background-color: #dce1e8;
    }
`;

const PopupBody = styled.div`
    padding: 0 32px 32px 32px;
`;

interface ContactPopupProps {
    width?: string;
    title: string;
    onClose: () => void;
    children: React.ReactNode;
}

const ContactPopup: React.FC<ContactPopupProps> = (props) => {
    const popupRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        const handlerClick = (e: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
                props.onClose();
            }
        };
    
        document.addEventListener('mousedown', handlerClick);
        return () => {
            document.removeEventListener('mousedown', handlerClick);
        };
    }, [props]);
    return (
        <PopupWidget>
            <Container $width={props.width ? props.width : '650px'} $backgroundColor="#FFF" $borderRadius="12px" ref={popupRef}>
                <RowWidget $justifyContent="space-between" $alignItems="center" $padding="32px 32px 8px 32px">
                    <Text $fontSize="32px" $fontWeight="600">{props.title}</Text>

                    <PopupCrosIcon src={CrossIcon} alt="icon" onClick={props.onClose} />
                </RowWidget>

                <PopupBody>{props.children}</PopupBody>
            </Container>
        </PopupWidget>
    )
}

export default ContactPopup;