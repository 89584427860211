import { useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { confirmPasswordReset } from "firebase/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

import { auth } from "../../configs/firebase-config";

import InputPassword from "../../components/Inputs/InputPassword";

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";


const ResetPassword = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    const [searchParams] = useSearchParams();

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [errorMessages, setErrorMessages] = useState({
        newPassword: '',
        confirmNewPassword: ''
    });
    
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

    const validate = () => {
        const newErrors = {
            newPassword: '',
            confirmNewPassword: '',
        };
        
        if (newPassword.length < 8)
            newErrors.newPassword = t('messages.errors.input.password');

        if (newPassword !== confirmPassword) {
            newErrors.newPassword = t('messages.errors.input.password_match');
            newErrors.confirmNewPassword = t('messages.errors.input.password_match');
        }

        return newErrors;
    };

    const handleResetPassword = async (e: any) => {
        e.preventDefault();
        
        const oobCode = searchParams.get('oobCode');
        if (!oobCode) {
            toast.error('Invalid or expired reset link.');
            return;
        }

        const validationErrors = validate();
        if (Object.values(validationErrors).some(error => error))
            return setErrorMessages(validationErrors);

        try {
            await confirmPasswordReset(auth, oobCode, newPassword);
            setPasswordResetSuccess(true);
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    if (passwordResetSuccess) {
        return (
            <RowWidget $width="100%" $height="95vh" $justifyContent="center" $alignItems="center">
                <RowWidget $height="100%" $width="360px" $flexDirection="column" $justifyContent="space-between">
                    <Container $height="100%" $margin="64px 0 0 0">
                        <RowWidget $justifyContent="center">
                            <img style={{ height: '32px', marginRight: '8px', }} src="images/img_group.svg" alt="logo" />
                            <img style={{ height: '32px' }} src="images/img_group_light_blue_a700.svg" alt="logo" />
                        </RowWidget>

                        <Container $height="64px" />

                        <RowWidget $justifyContent="center">
                            <img src="images/reset_success.svg" alt="key" style={{ width: '80px', height: '80px' }} />
                        </RowWidget>

                        <Container $height="16px" />

                        <Text $fontWeight="bold" $fontSize="24px" $textAlign="center">{t('auth.reset_password.success.title')}</Text>

                        <Container $height="8px" />

                        <Text $color="#908F94" $textAlign="center">{t('auth.reset_password.success.description')}</Text>

                        <Container $height="32px" />

                        <RowWidget $justifyContent="center">
                            <PrimaryButton 
                                onClick={() => navigate('/sign-in')}
                                title={t('auth.sign_in.title')}
                                $width="80%"
                            />
                        </RowWidget>

                        <Container $height="16px" />
                    </Container>
                    
                    <Text $textAlign="center" $color="#7E7D83" $fontSize="14px">Copyright © 2024 LegalAssists</Text>
                </RowWidget>
            </RowWidget>
        );
    }

    return (
        <RowWidget $width="100%" $height="95vh" $justifyContent="center" $alignItems="center">
            <RowWidget $height="100%" $width="360px" $flexDirection="column" $justifyContent="space-between">
                <Container $height="100%" $margin="64px 0 0 0">
                    <RowWidget $justifyContent="center">
                        <img style={{ height: '32px', marginRight: '8px', }} src="images/img_group.svg" alt="logo" />
                        <img style={{ height: '32px' }} src="images/img_group_light_blue_a700.svg" alt="logo" />
                    </RowWidget>

                    <Container $height="64px" />

                    <RowWidget $justifyContent="center">
                        <img src="images/img_close.svg" alt="key" style={{ width: '80px', height: '80px' }} />
                    </RowWidget>

                    <Container $height="16px" />

                    <Text $fontWeight="bold" $fontSize="24px" $textAlign="center">{t('auth.reset_password.title')}</Text>

                    <Container $height="8px" />

                    <Text $color="#908F94" $textAlign="center">{t('auth.reset_password.description')}</Text>

                    <Container $height="32px" />

                    <InputPassword 
                        label={t('input.new_password_label')}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        errorMessage={errorMessages.newPassword}
                    />

                    <Container $height="16px" />

                    <InputPassword 
                        label={t('input.confirm_new_password_label')}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        errorMessage={errorMessages.confirmNewPassword}
                    />

                    <Container $height="32px" />

                    <PrimaryButton 
                        onClick={handleResetPassword}
                        title={t('button.confirm')}
                        $width="100%"
                    />

                    <Container $height="8px" />
                    
                    <SecondaryButton 
                        onClick={() => navigate('/sign-in')}
                        title={t('button.back_to_login')}
                        $borderColor="#E9E9EA"
                    />
                </Container>
                
                <Text $textAlign="center" $color="#7E7D83" $fontSize="14px">Copyright © 2024 LegalAssists</Text>
            </RowWidget>
        </RowWidget>
    );
}

export default ResetPassword;