import UndoIcon from '../../assets/tools/undo.svg';
import RedoIcon from '../../assets/tools/redo.svg';

import AlignLeftIcon from '../../assets/tools/align_left_icon.svg';
import AlignCenterIcon from '../../assets/tools/align_center_icon.svg';
import AlignRightIcon from '../../assets/tools/align_right_icon.svg';
import AlignJustifyIcon from '../../assets/tools/align_justify_icon.svg';

import BoldIcon from '../../assets/tools/bold.svg';
import ItalicIcon from '../../assets/tools/italic.svg';
import UnderlineIcon from '../../assets/tools/underline.svg';
import CanceledIcon from '../../assets/tools/canceled.svg';

import DotListIcon from '../../assets/tools/dot_list.svg';
import NumberedListIcon from '../../assets/tools/numbered_list.svg';

import RemoveIcon from '../../assets/tools/remove_icon.svg';


export const toolbar = {
    options: ['history', 'blockType', 'fontSize', 'textAlign', 'colorPicker', 'inline', 'list', 'link', 'remove'],
    history: {
        options: ['undo', 'redo'],
        undo: { icon: UndoIcon, className: 'tool-icon' },
        redo: { icon: RedoIcon, className: 'tool-icon' },
    },
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        bold: { icon: BoldIcon, className: 'tool-icon' },
        italic: { icon: ItalicIcon, className: 'tool-icon' },
        underline: { icon: UnderlineIcon, className: 'tool-icon' },
        strikethrough: { icon: CanceledIcon, className: 'tool-icon' },
        superscript: true,
        subscript: true,
    },
    textAlign: {
        inDropdown: true,
        options: ['left', 'center', 'right', 'justify'],
        left: { icon: AlignLeftIcon, className: undefined },
        center: { icon: AlignCenterIcon, className: undefined },
        right: { icon: AlignRightIcon, className: undefined },
        justify: { icon: AlignJustifyIcon, className: undefined },
    },
    colorPicker: {
        className: 'tool-icon',
    },
    list: {
        inDropdown: false,
        options: ['unordered', 'ordered'],
        unordered: { icon: DotListIcon, className: 'tool-icon' },
        ordered: { icon: NumberedListIcon, className: 'tool-icon' },
    },
    link: {
        options: ['link'],
        link: { className: 'tool-icon' },
        dropdownClassName: 'dropdown-class',
    },
    remove: {
        icon: RemoveIcon,
        className: 'tool-icon',
    },
};
