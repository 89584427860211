import React from "react";

import styled from "styled-components";

import User from '../../assets/user.svg';
import EditIcon from '../../assets/EditIcon.svg';
import DustbinIcon from '../../assets/DustbinIcon.svg';

import { Member } from "../../entities/Team/models/types/Team.types";

import { formatDate } from "../../shared/utils/date_format";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Container from "../../shared/lib/widgets/Container";


const TeamMemberCardStyle = styled.div`
    padding: 8px 10px;
    background-color: #FFF; 
    border-bottom: 1px solid #E9E9EA;
`;

const TeamMemberCellStyle = styled.div<{ width?: string, minWidth?: string }>`
    width: ${(props) => props.minWidth ? props.minWidth : props.width};
    min-width: ${(props) => props.minWidth ? props.minWidth : ''};
    font-size: 14px;
    color: #201F29;
`;

interface TeamMemberCardProps {
    memberData: Member;
    isAdmin?: boolean;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({ memberData, isAdmin, onEditClick, onDeleteClick }) => {
    return (
        <TeamMemberCardStyle>
            <RowWidget $alignItems="center" $gap="8px">
                <RowWidget $width="100%" $alignItems="center">
                    <img src={memberData.image ? memberData.image : User} alt="user" style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover', marginRight: '12px', }} />
                    
                    <Container>
                        <Text $fontWeight="bold" $color="#201F29">{`${memberData.first_name} ${memberData.last_name}`}</Text>

                        <Container $height="4px" />
                        
                        <Text $color="#7E7D83" $fontSize="14px">{memberData.email}</Text>
                    </Container>
                </RowWidget>

                <TeamMemberCellStyle minWidth="120px">{memberData.dateAdded ? formatDate(memberData.dateAdded) : ''}</TeamMemberCellStyle>

                <TeamMemberCellStyle minWidth="120px">{memberData.lastActive ? formatDate(memberData.lastActive) : ''}</TeamMemberCellStyle>
                
                <TeamMemberCellStyle minWidth="50px">
                    {
                        isAdmin &&
                        <RowWidget $justifyContent="space-between">
                            <img 
                                onClick={onEditClick}
                                src={EditIcon} 
                                alt="icon" 
                                style={{ 
                                    width: '18px', 
                                    height: '18px', 
                                    cursor: 'pointer' 
                                }} 
                            />
                            <img 
                                onClick={onDeleteClick}
                                src={DustbinIcon} 
                                alt="icon" 
                                style={{ 
                                    width: '18px', 
                                    height: '18px', 
                                    cursor: 'pointer' 
                                }} 
                            />
                        </RowWidget>
                    }
                </TeamMemberCellStyle>
            </RowWidget>
        </TeamMemberCardStyle>
    );
}

export default TeamMemberCard;