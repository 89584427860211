import React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";


const AcademyCardStyle = styled.div<{ $backgroundColor: string }>`
    width: 100%;
    max-width: 250px;
    min-height: 200px;
    background-color: ${(props) => props.$backgroundColor ?? '#FFEEEE'};
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const AcademyCardIcon = styled.img`
    width: 50px;
    height: 50px;
`;

interface AcademyCardProps {
    onClick: () => void;
    icon: string;
    title: string;
    backgroundColor: string;
}

const AcademyCard: React.FC<AcademyCardProps> = (props) => {
    const { t } = useTranslation();
    
    return (
        <AcademyCardStyle onClick={props.onClick} $backgroundColor={props.backgroundColor}>
            <AcademyCardIcon src={props.icon} alt="icon" />
            
            <Container $height="16px" />

            <Text $textAlign="center" $fontWeight="bold" $fontSize="20px">{t(`academy.${props.title}.title`)}</Text>

            <Container $height="16px" />

            <Text $textAlign="center" $fontWeight="400" $fontSize="14px">{t(`academy.${props.title}.description`)}</Text>
        </AcademyCardStyle>
    );
}

export default AcademyCard;