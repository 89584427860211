import React from 'react';

import styled from 'styled-components';

import Text from '../../shared/lib/widgets/Text';
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import { useTranslation } from 'react-i18next';


const ToggleButtonStyle = styled.div<{ $isSelected: boolean }>`
    display: flex;
    align-items: center;
    height: 100%;
    border: 2px solid ${(props) => props.$isSelected ? '#E9E9EA' : 'transparent'};
    background-color: ${(props) => props.$isSelected ? '#FFF' : 'transparent'};
    border-radius: 8px;
    padding: 4px 12px;
    width: 100%;
    justify-content: center;
    cursor: pointer;
`;

interface SaleToggleProps {
    onClick: (toggleState: boolean) => void;
    toggleState: boolean;
}

const SaleToggle: React.FC<SaleToggleProps> = ({ onClick, toggleState }) => {
    const { t } = useTranslation();
    
    return (
        <Container $width='350px' $height='40px' $backgroundColor='#F6F7F9' $borderRadius='8px'>
            <RowWidget $width='100%' $height='100%'>
                <ToggleButtonStyle
                    $isSelected={toggleState}
                    onClick={() => onClick(true)}
                >
                    <Text $fontSize='13px' $fontWeight={toggleState ? '600' : '400'}>{t('toggle.annually')}</Text>
                    
                    <Container $width='8px' />
                    
                    <Container $backgroundColor="#EDF8E8" $padding="2px 6px" $borderRadius="6px" $cursor='pointer'>
                        <Text $color="#306F11" $fontWeight="400" $fontSize="12px">Save 20%</Text>
                    </Container>
                </ToggleButtonStyle>

                <ToggleButtonStyle
                    $isSelected={!toggleState}
                    onClick={() => onClick(false)}
                >
                    <Text $fontSize='13px' $fontWeight={!toggleState ? '600' : '400'}>{t('toggle.monthly')}</Text>
                </ToggleButtonStyle>
            </RowWidget>
        </Container>
    );
}

export default SaleToggle;