import React from "react";
import styled from "styled-components";
import RowWidget from "../../shared/lib/widgets/RowWidget";


type ButtonColorVariants = 'default' | 'red';

interface ButtonColorStyle {
    normal: string;
    hovered: string;
    active: string;
}

const ButtonColorStyles: Record<ButtonColorVariants, ButtonColorStyle> = {
    default: {
        normal: '#4158E3',
        hovered: '#2E3E9F',
        active: '#28368A',
    },
    red: {
        normal: '#FF5252',
        hovered: '#B33939',
        active: '#9C3232',
    }
};

export const getButtonColorStyle = (type?: string): ButtonColorStyle => {
    return ButtonColorStyles[type as ButtonColorVariants] || ButtonColorStyles.default;
};

const ButtonLeading = styled.img`
    width: '20px';
    color: #fff;
    margin-right: 8px;
`;


const TextButtonStyle = styled.button<TextButtonProps>`
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    color: ${(props) => props.color || getButtonColorStyle(props.type).normal};
    font-weight: ${(props) => props.fontWeight ? props.fontWeight : ''};
    font-size: ${(props) => props.fontSize ? props.fontSize : '16px'};
    opacity: ${(props) => props.isDisabled ? '.7' : ''};
    outline: none;
    border: none;
    background-color: transparent;
    &:hover {
        color: ${(props) => getButtonColorStyle(props.type).hovered};
    }
    &:active {
        color: ${(props) => getButtonColorStyle(props.type).active};
    }
    &:focus {
        color: ${(props) => getButtonColorStyle(props.type).active};
    }
`;

interface TextButtonProps {
    isDisabled?: boolean;

    type?: string;
    onClick?: (e: any) => void;
    leading?: string;
    title?: string;
    color?: string;
    fontWeight?: string;
    fontSize?: string;
}

const TextButton: React.FC<TextButtonProps> = (props) => {
    return (
        <RowWidget 
            $alignItems="center"
            onClick={(e) => {
                if (!props.isDisabled && props.onClick) {
                    props.onClick(e);
                }
            }}
            style={{
                cursor: 'pointer'
            }}
        >
            {
                props.leading &&
                    <ButtonLeading 
                        src={props.leading}
                        alt="icon"
                    />
            }

            <TextButtonStyle
                type={props.type}
                color={props.color}
                fontWeight={props.fontWeight}
                fontSize={props.fontSize}
            >{props.title}</TextButtonStyle>
        </RowWidget>
    );
}

export default TextButton;