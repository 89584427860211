import React from "react";

import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import ArrowLeft from "../../assets/Arrowleft.svg";

import RowWidget from '../../shared/lib/widgets/RowWidget';
import Container from '../../shared/lib/widgets/Container';


interface SubHeaderProps {
    title: string;
    to: any;
    leading?: string;
    border?: string;
}

const SubHeaderStyle = styled.div`
    position: sticky;
    top: 70px;
    left: 0;
    right: 0;
    z-index: 2;
    height: 70px;
    background-color: #fff;
    padding: 24px 16px;
    width: 100%; 
`;

const SubHeaderTitle = styled.div`
    color: #201F29;
    font-size: 18px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 400px) {
        display: none;
    }
`;

const SubHeader: React.FC<SubHeaderProps> = ({ title, to, leading, border }) => {
    const navigate = useNavigate();
    
    return (
        <SubHeaderStyle style={{ borderBottom: border }}>
            <RowWidget $alignItems="center" $width='fit-content' onClick={() => navigate(to)} style={{ cursor: 'pointer' }}>
                <img src={ArrowLeft} width='18px' height='18px' alt='icon' />

                <Container $width='10px' />

                {
                    leading &&
                    <img src={leading} alt='icon' style={{ width: '28px', height: '28px', marginRight: '10px' }} />
                }
                
                <SubHeaderTitle>{title}</SubHeaderTitle>
            </RowWidget>
        </SubHeaderStyle>
    );
};

export default SubHeader;
