import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { isEmail } from "../../../shared/utils/validators";

import TextButton from "../../Buttons/TextButton";
import PrimaryButton from "../../Buttons/PrimaryButton";

import Input from "../../Inputs/Input";
import InputPassword from "../../Inputs/InputPassword";

import Text from "../../../shared/lib/widgets/Text";
import Container from "../../../shared/lib/widgets/Container";
import RowWidget from "../../../shared/lib/widgets/RowWidget";
import { toast } from "react-toastify";


const AffiliateSignUp = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPasword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    const [errorState, setErrorState] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: '',
    });
    
    const validate = () => {
        const newErrors = {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            confirm_password: '',
        };
        
        if (!firstName) 
            newErrors.first_name = t('messages.errors.input.empty');

        if (!lastName) 
            newErrors.last_name = t('messages.errors.input.empty');

        if (!isEmail(email))
            newErrors.email = t('messages.errors.input.email');
        
        if (password.length < 8)
            newErrors.password = t('messages.errors.input.password');
        
        if (confirmPassword.length < 8)
            newErrors.confirm_password = t('messages.errors.input.password');
        
        if (password !== confirmPassword){
            newErrors.password = t('messages.errors.input.password_match');
            newErrors.confirm_password = t('messages.errors.input.password_match');
        }

        return newErrors;
    };
    
    const handleSignUp = (e: any) => {
        e.preventDefault();
        
        const validationErrors = validate();
        if (Object.values(validationErrors).some(error => error))
            return setErrorState(validationErrors);

        toast.info('This part is under development.');
        navigate('/affiliate/email-verification');
    }

    return (
        <RowWidget $width="100%" $justifyContent="center" $alignItems="center" $margin="32px 0">
            <RowWidget $width="360px" $flexDirection="column" $justifyContent="space-between">
                <Container $margin="40px 0 32px 0" $height="100%">
                    <RowWidget $justifyContent="center">
                        <img style={{ height: '32px', marginRight: '8px', }} src="/images/img_group.svg" alt="logo" />
                        <img style={{ height: '32px' }} src="/images/img_group_light_blue_a700.svg" alt="logo" />
                    </RowWidget>

                    <Container $height="64px" />

                    <Text $fontWeight="bold" $fontSize="28px" $textAlign="center">{t('affiliate.sign_up.title')}</Text>

                    <Container $height="8px" />

                    <Text $color="#908F94" $textAlign="center" $fontSize="14px">{t('affiliate.sign_up.subtitle')}</Text>

                    <Container $height="32px" />

                    <Input 
                        label={t('input.user_name_label')}
                        placeholder={t('input.user_name_placeholder')}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        errorMessage={errorState.first_name}
                    />

                    <Container $height="12px" />

                    <Input 
                        label={t('input.user_surname_label')}
                        placeholder={t('input.user_surname_placeholder')}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        errorMessage={errorState.last_name}
                    />

                    <Container $height="12px" />

                    <Input 
                        label={t('input.user_email_label')}
                        placeholder={t('input.email_placeholder')}
                        name='otp'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        errorMessage={errorState.email}
                    />
                    
                    <Container $height="12px" />

                    <InputPassword 
                        label={t('input.new_password_label')}
                        placeholder={t('input.password_placeholder')}
                        name='otp'
                        value={password}
                        onChange={(e) => setPasword(e.target.value)}
                        errorMessage={errorState.password}
                    />

                    <Container $height="12px" />

                    <InputPassword 
                        label={t('input.confirm_new_password_label')}
                        placeholder={t('input.password_placeholder')}
                        name='otp'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        errorMessage={errorState.confirm_password}
                    />

                    <Container $height="32px" />

                    <PrimaryButton 
                        onClick={handleSignUp}
                        title={t('auth.sign_up.title')}
                        $width="100%"
                    />
                    
                    <Container $height="20px" />

                    <RowWidget $justifyContent="center">
                        <Text $margin="0 4px" $fontSize="14px" $color="#46454D">{t('auth.sign_up.no_account')}</Text>
                        <TextButton 
                            onClick={() => navigate('/affiliate/sign-in')}
                            title={t('auth.sign_in.title')}
                            fontWeight="bold" 
                            fontSize="14px" 
                            color="#007FFF" 
                        />
                    </RowWidget>
                </Container>
                
                <Text $textAlign="center" $color="#7E7D83" $fontSize="14px">Copyright © 2024 LegalAssists</Text>
            </RowWidget>
        </RowWidget>
    );
}


export default AffiliateSignUp;
