import { useSearchParams } from "react-router-dom";

import EmailVerification from "./EmailVerification";
import ResetPassword from "./ResetPassword";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";
import Text from "../../shared/lib/widgets/Text";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const AuthMode = () => {
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    const mode = searchParams.get('mode');

    if (mode === 'resetPassword') {
        return <ResetPassword />
    }

    if (mode === 'verifyEmail') {
        return <EmailVerification />
    }
    
    return (
        <Container $margin="16px" $border="1px solid grey" $borderRadius="16px">
            <RowWidget $justifyContent="center" $alignItems="center" $gap="8px" $height="80px">
                <Text $color="grey" $fontSize="18px">{t('empty_list.page_not_found')}</Text>
                <Link style={{ color: '#4158E3', fontSize: '18px', fontWeight: 'bold' }} to='/sign-in'>Sign in</Link>
            </RowWidget>
        </Container>
    );
}


export default AuthMode;