import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

import { EditorState, RichUtils, DraftHandleValue, DraftEditorCommand, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import * as XLSX from 'xlsx';
import Spreadsheet, { Matrix } from 'react-spreadsheet';

import Mammoth from 'mammoth';

import EditorHeader from "./EditorHeader";

import { toolbar } from "../../shared/utils/editorTools";

import Container from "../../shared/lib/widgets/Container";

import './richEditor.css';


const DocumentEditor: React.FC = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [spreadsheetData, setSpreadsheetData] = useState<{ value: any }[][]>([]);
    const [isSpreadsheet, setIsSpreadsheet] = useState(false);
    const [spreadsheetError, setSpreadsheetError] = useState<string | null>(null);

    const onChange = (newEditorState: EditorState) => setEditorState(newEditorState);

    const handleKeyCommand = (command: DraftEditorCommand, editorState: EditorState): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const location = useLocation();
    const { file } = location.state || {};

    useEffect(() => {
        const processDocxFile = async (file: File) => {
            const reader = new FileReader();

            reader.onload = async (event) => {
                const arrayBuffer = event.target?.result as ArrayBuffer;
                const result = await Mammoth.convertToHtml({ arrayBuffer });
                const html = result.value;

                const blocksFromHTML = convertFromHTML(html);
                const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
                setEditorState(EditorState.createWithContent(contentState));
            };

            reader.readAsArrayBuffer(file);
        };

        const processXlsxFile = async (file: File) => {
            try {
                const reader = new FileReader();
        
                reader.onload = (e) => {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    
                    const data = new Uint8Array(arrayBuffer);
                    const binaryString = Array.from(data).map(byte => String.fromCharCode(byte)).join('');
        
                    const workbook = XLSX.read(binaryString, { type: 'binary' });
        
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
        
                    const rows: any[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        
                    if (rows.length === 0) {
                        throw new Error("Empty or invalid Excel file");
                    }
        
                    const spreadsheetArray = rows.map((row: any[]) =>
                        row.map(cell => ({ value: cell }))
                    );
        
                    setSpreadsheetData(spreadsheetArray);
                    setIsSpreadsheet(true);
                    setSpreadsheetError(null);
                };
        
                reader.readAsArrayBuffer(file);
            } catch (error) {
                setSpreadsheetError("Error processing the Excel file. Please check if the file format is correct.");
            }
        };

        const processTxtFile = (file: File) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const text = e.target?.result as string;
                const contentState = ContentState.createFromText(text);
                setEditorState(EditorState.createWithContent(contentState));
            };

            reader.readAsText(file);
        };

        const processFile = async (file: File) => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            if (fileExtension === 'docx') {
                processDocxFile(file);
            } else if (fileExtension === 'xlsx') {
                processXlsxFile(file);
            } else if (fileExtension === 'txt') {
                processTxtFile(file);
            } else {
                toast.error('Unsupported file format');
            }
        };

        if (file) {
            processFile(file);
        }
    }, [file]);

    const handleSpreadsheetChange = (data: Matrix<{ value: any }>) => {
        setSpreadsheetData(data as { value: any }[][]);
    };
    
    const ensureFullScreenSpreadsheet = (data: { value: any }[][], minRows = 40, minCols = 25) => {
        const rows = Math.max(data.length, minRows);
        const cols = Math.max(data[0]?.length || 0, minCols);
    
        const extendedData = Array.from({ length: rows }, (_, rowIndex) =>
            Array.from({ length: cols }, (_, colIndex) => data[rowIndex]?.[colIndex] || { value: "" })
        );
    
        return extendedData;
    };

    const extendedSpreadsheetData = ensureFullScreenSpreadsheet(spreadsheetData);

    return (
        <>
            <EditorHeader file={file} />

            <Container $backgroundColor="#F9FAFC">
                {
                    spreadsheetError &&
                        <div style={{ color: 'red' }}>
                            {spreadsheetError}
                        </div>
                }
                {
                    isSpreadsheet ?
                        <div style={{
                            width: '100%',
                            height: '100%',
                            overflow: 'auto',
                        }}>
                            <Spreadsheet data={extendedSpreadsheetData} onChange={handleSpreadsheetChange} />
                        </div>
                        :
                        <Editor
                            toolbar={toolbar}
                            editorState={editorState}
                            handleKeyCommand={handleKeyCommand}
                            onEditorStateChange={onChange}
                        />
                }
            </Container>
        </>
    );
};

export default DocumentEditor;
