import React, { useState } from 'react';

import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'

import CopyMessage from '../../assets/copy.svg';
import ResendMessage from '../../assets/resend.png';
import FeedbackLike from '../../assets/feedback-like.svg';
import AIDocumentIcon from '../../assets/ai_document_icon.svg'

import FeedbackPopup from '../Popups/FeedbackPopup';

import { Message } from '../../entities/Case/models/types/Case.types';

import RowWidget from '../../shared/lib/widgets/RowWidget';
import Container from '../../shared/lib/widgets/Container';
import Text from '../../shared/lib/widgets/Text';

import styleMessage from '../../shared/utils/markdown-styles.module.css';
import Tooltip from '../../shared/lib/widgets/Tooltip';


const MessageCardStyle = styled.div`
    display: flex;
    gap: 12px;
    width: 840px;
    margin: 0 auto 28px auto;

    @media (max-width: 890px) {
        width: 95%;
    }
    @media (max-width: 620px) {
        margin-left: 16px;
    }
`;

const Avatar = styled.img`
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
`;

const PlaceholderAvatar = styled.div`
    background-color: #4158E3;
    color: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    min-width: 40px;
    height: 40px;
`;

const IconContainer = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
    &:hover {
        background-color: #F6F7F9;
        .icon_hover {
            filter: sepia(70%) hue-rotate(190deg) saturate(500%);
        }
    }
`;

const Icon = styled.img<{ $width?: string }>`
    width: ${(props) => props.$width || '24px'};
    height: ${(props) => props.$width || '24px'};
    margin: 4px;
    object-fit: cover;
`;

const MessageDocument = styled.div`
    cursor: pointer;
    margin-top: 10px;
    background-color: #FFF;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    align-items: start;
    gap: 10px;
    max-width: 300px;
`;

interface MessageCardProps {
    message: Message;
    image?: string;
    resendMessage: () => void;
}

const MessageCard: React.FC<MessageCardProps> = ({ message, image, resendMessage }) => {
    const { t } = useTranslation();
    
    const [feedbackPopup, setFeedbackPopup] = useState(false);

    return (
        <MessageCardStyle>
            {
                feedbackPopup &&
                <FeedbackPopup
                    onSubmit={() => toast.info(t('messages.info.service_not_available'))}
                    onClose={() => setFeedbackPopup(false)}
                />
            }
            {
                image ?
                    <Avatar src={image} alt='avatar' />
                    :
                    <PlaceholderAvatar>AI</PlaceholderAvatar>
            }

            <Container $width='100%'>
                {/* {
                    message.sender !== 'user_proxy' ?
                        <Text $margin='0 0 8px 0'>
                            Used assistant: <b style={{ color: '#4158E3' }}>Name</b> / 
                            Used skill: <b style={{ color: '#234571' }}>Skill</b>
                        </Text>
                        :
                        null
                } */}
                <Container $backgroundColor={image ? '#ECEEFC' : '#FFF'} $borderRadius='12px' $width='100%'>
                    <Text $padding='12px'>
                        <ReactMarkdown
                            children={message.content}
                            remarkPlugins={[remarkGfm]}
                            className={styleMessage.reactMarkDown}
                        />
                    </Text>

                    {
                        (message.sender !== 'chat' && message.sender !== 'user_proxy') &&
                        <RowWidget $alignItems='center' $justifyContent='space-between' $padding='8px 12px'>
                            <RowWidget $gap='8px'>
                                <IconContainer onClick={() => setFeedbackPopup(true)}>
                                    <Icon
                                        src={FeedbackLike}
                                        alt='icon'
                                        className='icon_hover'
                                    />
                                </IconContainer>
                                <IconContainer onClick={() => setFeedbackPopup(true)}>
                                    <Icon
                                        src={FeedbackLike}
                                        alt='icon'
                                        style={{ rotate: '180deg' }}
                                        className='icon_hover'
                                    />
                                </IconContainer>
                            </RowWidget>

                            <RowWidget $gap='8px'>
                                <Tooltip
                                    title={t('tooltip.copy_message.title')}
                                    description={t('tooltip.copy_message.description')}
                                >
                                    <IconContainer onClick={async () => {
                                        toast.success(t('messages.success.message_copied'));
                                        await navigator.clipboard?.writeText(message.content);
                                    }}>
                                        <Icon
                                            src={CopyMessage}
                                            alt='icon'
                                            className='icon_hover'
                                            $width='18px'
                                        />
                                    </IconContainer>
                                </Tooltip>

                                <IconContainer onClick={resendMessage}>
                                    <Icon
                                        src={ResendMessage}
                                        alt='icon'
                                        className='icon_hover' 
                                        $width='18px'
                                    />
                                </IconContainer>
                            </RowWidget>
                        </RowWidget>
                    }
                </Container>

                {
                    message.document_path &&
                        <MessageDocument onClick={() => toast.info("The download of the document is currently not available")}>
                            <img 
                                src={AIDocumentIcon} 
                                alt='doc' 
                            />

                            <Text $overflow='hidden' $textOverflow='ellipsis' style={{ whiteSpace: 'nowrap' }}>
                                <Text $overflow='hidden' $textOverflow='ellipsis'>{message.document_path?.substring(22)}</Text>
                                <Text $color='#A4A3A7' $margin='6px 0 0 0' $fontSize='14px'>AI | --/--/----</Text>
                            </Text>
                        </MessageDocument>
                }
            </Container>
        </MessageCardStyle>
    );
};

export default MessageCard;
