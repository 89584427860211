// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header-bar {
    display: none !important;
}

#pdf-controls {
    display: none !important;
}



/* #sub-frame-error-details {
    height: 100% !important;
    width: 100% !important;
}

#sub-frame-error-details strong {
    display: none !important;
} */`, "",{"version":3,"sources":["webpack://./src/shared/lib/widgets/document-preview.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;AAC5B;;;;AAIA;;;;;;;GAOG","sourcesContent":["#header-bar {\n    display: none !important;\n}\n\n#pdf-controls {\n    display: none !important;\n}\n\n\n\n/* #sub-frame-error-details {\n    height: 100% !important;\n    width: 100% !important;\n}\n\n#sub-frame-error-details strong {\n    display: none !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
