import React from "react";

import styled from "styled-components";

import InputComponent from "./InputComponent";


const InputStyle = styled.input<{ $backgroundColor?: string, $error?: boolean }>`
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 6px;
    font-family: 'SF Pro Display';    
    border: 1px solid ${(props) => props.$error ? '#FF5252' : 'transparent'};
    background-color: ${(props) => props.$error ? '#FFEEEE' : props.$backgroundColor ? props.$backgroundColor : '#f6f7f9'};
    &:focus{
        border: 1px solid #4158E3;
        outline: none;
    }
    &:hover {
        border: 1px solid #4158E3;
    }
    &::placeholder {
        color: #A4A3A7;
    }
    @media (max-width: 568px) {
        font-size: 14px;
    }
`;

interface InputComponentProps {
    label?: string;
    backgroundColor?: string;
    name?: string;
    type?: string;
    placeholder?: string;
    readOnly?: boolean;
    value?: string;
    onChange?: (e: any) => void;
    errorMessage?: string;
    autoComplete?: string;
}

const Input: React.FC<InputComponentProps> = ({
    label,
    backgroundColor,
    name,
    type = 'text',
    placeholder,
    readOnly,
    value,
    onChange,
    errorMessage,
    autoComplete = 'off',
}) => {
    return (
        <InputComponent label={label} errorMessage={errorMessage}>
            <InputStyle 
                $error={!!errorMessage}
                name={name}
                $backgroundColor={backgroundColor}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                readOnly={readOnly}
                autoComplete={autoComplete}
            />
        </InputComponent>
    );
}

export default Input;