import React, { useState } from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import LoadingIcon from '../../assets/loading.svg';
import SendRightIcon from "../../assets/SendRightIcon.svg";
// import UserIcon from "../../assets/UserIcon.svg";
// import BlueCrossIcon from "../../assets/BlueCrossIcon.svg";
// import SearchAnalysisImage from "../../assets/SearchAnalysisImage.svg";

import { editCase } from "../../entities/Case/models/services/case.services";

import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";

// import { $api } from "../../shared/api/api";

// import { actionNames } from "../../shared/consts/default_values";
import { useAppDispatch } from "../../shared/components/useAppDispatch/useAppDispatch";
import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";

// import { casesActions } from "../../entities/Cases/models/slices/CasesSlice";

// import DropDown from "../DropDown/DropDown";
import MarketplaceDropDown from "../DropDown/MarketplaceDropDown";

import InputTextArea from "../Inputs/InputTextArea";

import Tooltip from '../../shared/lib/widgets/Tooltip';

// import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";
// import Container from "../../shared/lib/widgets/Container";


interface SendMessageCardProps {
    isLoading?: boolean;
    onClick: (e: string) => void;
}

const SendMessageCardContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #F9FAFC;
    border-radius: 8px;
    padding-bottom: 8px;
    display: flex;
    justify-content: center;
    width: 900px;
    @media (max-width: 900px) {
        width: 100%;
        padding-bottom: 0;
    }
`;

const SendMessageCardStyle = styled.div`
    background-color: #fff;
    border: 1px solid rgba(233, 233, 234, 1);
    border-radius: 8px;
    width: 100%;
    @media (max-width: 956px) {
        margin-left: 15px;
    }
`;

// const SendMessageInfo = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 8px;
//     margin: 8px 16px 0 16px;
//     padding-bottom: 8px;
//     border-bottom: 1px solid rgba(233, 233, 234, 1);
//     overflow-x: auto;
//     &::-webkit-scrollbar {
//         height: 6px;
//     }
//     &::-webkit-scrollbar-track {
//         background: transparent; 
//     }
//     &::-webkit-scrollbar-thumb {
//         cursor: pointer;
//         border-radius: 40px;
//         background: #E8ECF0; 
//     }
//     &::-webkit-scrollbar-thumb:hover {
//         background: #aaa; 
//     }
// `;

const LoadingIconAnimation = styled.img`
    width: 25px;
    height: 25px;
    animation: rotation 1s infinite linear;
    @keyframes rotation {
        to{
        transform: rotate(-1turn)
        }
    }
`;

// const ChatInfo = styled.div<{ $borderRadius: string, $backgroundColor: string }>`
//     height: 35px;
//     border-radius: ${(props) => props.$borderRadius ?? ''};
//     background-color: ${(props) => props.$backgroundColor ?? ''};
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 0 10px;
//     gap: 8px;
//     white-space: nowrap;
// `;

// const InfoIcons = styled.img<{ $size?: string }>`
//     width: ${(props) => props.$size ?? '12px'};
//     height: ${(props) => props.$size ?? '12px'};
//     cursor: pointer;
// `;

// const SkillContainer = styled.div`
//     display: flex;
//     align-items: center; 
//     gap: 8px; 
//     flex-wrap: wrap;
//     padding: 12px; 
//     background-color: #f2f4f6; 
//     border-radius: 0 0 10px 10px;
//     border-top: 1px solid rgba(233, 233, 234, 1);
//     @media (max-width: 535px) {
//         .responsive-text {
//             display: none;
//         }
//         max-height: 60px;
//         align-items: start; 
//         overflow-y: auto;
//         &::-webkit-scrollbar {
//             width: 6px;
//         }
//         &::-webkit-scrollbar-track {
//             background: transparent; 
//         }
//         &::-webkit-scrollbar-thumb {
//             cursor: pointer;
//             border-radius: 40px;
//             background: #E8ECF0; 
//         }
//         &::-webkit-scrollbar-thumb:hover {
//             background: #aaa; 
//         }
//     }
// `;

const SendMessageCard: React.FC<SendMessageCardProps> = (props) => {
    const { t } = useTranslation();
    
    const caseData = useAppSelector((state: StateSchema) => state.case?.case);
    const [newMessage, setNewMessage] = useState("");
    // const [agent, setAgent] = useState<any>({
    //     id: '',
    //     profileImage: '',
    //     name: '',
    //     skills: [],
    // });

    const dispatch = useAppDispatch();

    // const user = useAppSelector((state: StateSchema) => state.user.userData);

    // useEffect(() => {
    //     const fetchAgent = async (agentId: string) => {
    //         try {
    //             const response = await $api.get(`/agents/${agentId}`);
    //             setAgent(response.data);
    //         } catch (error) {
    //             toast.error(t('messages.errors.agent.search'));
    //             setAgent({
    //                 id: '',
    //                 profileImage: '',
    //                 name: 'Not found',
    //                 skills: [],
    //             });
    //         }
    //     };

    //     if (caseData?.agent_id)
    //         fetchAgent(caseData?.agent_id);
    // }, [caseData?.agent_id, t]);

    // const [showAllSkills, setShowAllSkills] = useState(false);

    // const [skills, setSkills] = useState<string[]>([]);

    // const isMoreThanAMonthOld = (agentDate: Date) => {
    //     const currentDate = new Date();

    //     const oneMonthAgo = new Date();
    //     oneMonthAgo.setMonth(currentDate.getMonth() - 1);

    //     return agentDate < oneMonthAgo;
    // };

    // const isCollaboratorCanEdit = caseData?.collaborators?.some(coll => coll.id === user?.id && coll.edit === true);

    const handleSendMessage = (event: any) => {
        event.preventDefault();

        if (props.isLoading)
            return toast.info(t('messages.info.wait_generation'))

        if (!newMessage)
            return toast.info(t('messages.info.input_message'))

        // if (!isCollaboratorCanEdit)
        //     return toast.info('Utente non ha il permesso ad inviare i messaggi!')

        // if (!caseData?.agent_id)
        //     return toast.info(t('messages.info.select_agent'))

        // if (skills.length === 0)
        //     return toast.info(t('messages.info.select_skill'))

        // if (!user?.agent_ids.map((agentData) => agentData.id === caseData.agent_id ? isMoreThanAMonthOld(agentData.date) : null))
        //     return toast.info("Per favore aggiorna l'iscrizione per agent!")

        try {
            // setShowAllSkills(false);
            props.onClick(newMessage)
            setNewMessage("");
        } catch (e: any) {
            toast.error(t('messages.errors.cases.message_send'));
        }
    };

    const handleRowSelect = (agentId: any) => {
        // if (!isCollaboratorCanEdit)
        //     return toast.info('Utente non ha il permesso per cambiare agente!')

        dispatch(editCase({ id: caseData?.id, agent_id: agentId }))
    };

    return (
        <SendMessageCardContainer>
            <SendMessageCardStyle>
                {/* {
                    caseData?.agent_id &&
                        <SendMessageInfo>
                            {
                                caseData?.agent_id && 
                                    <ChatInfo $backgroundColor="#ECEEFC" $borderRadius="16px">
                                        <RowWidget $alignItems="center" $gap="8px">
                                            <InfoIcons src={UserIcon} alt="icon" $size="16px" />

                                            <Text $fontSize='14px' $fontWeight='400px' $color='#4158E3'>{agent?.name}</Text>
                                        </RowWidget>

                                        <InfoIcons src={BlueCrossIcon} alt="icon" onClick={() => {
                                        // if (!isCollaboratorCanEdit)
                                        //     return toast.info("Utente non ha il permesso per cambiare l'agente!");

                                        dispatch(editCase({ id: caseData?.id, agent_id: '' }));
                                        setSkills([]);
                                    }}/>
                                    </ChatInfo>
                            }
                            {
                                skills.map((skill) => 
                                    <ChatInfo key={skill} $backgroundColor="#F2F4F6" $borderRadius="8px">
                                        <RowWidget $alignItems="center" $gap="8px">
                                            <InfoIcons src={SearchAnalysisImage} alt="icon" $size="16px" />

                                            <Text $fontSize='14px' $fontWeight='400' $color='#153968'>{skill}</Text>
                                        </RowWidget>

                                        <InfoIcons src={BlueCrossIcon} alt="icon" onClick={() => setSkills((prevSkills) => prevSkills.filter((s) => s !== skill))}/>
                                    </ChatInfo>
                                )
                            }
                        </SendMessageInfo>
                } */}

                <RowWidget $margin="4px 16px 8px 16px" $gap="12px">
                    {/* {
                        !caseData?.agent_id &&
                            <MarketplaceDropDown onClick={handleRowSelect} />
                    } */}

                    <InputTextArea
                        padding="10px 0"
                        backcgroundColor="#FFF"
                        rows={4}
                        disabled={props.isLoading}
                        placeholder={t('input.chat_label')}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onSubmit={handleSendMessage}
                    />

                    <RowWidget $alignItems="end">
                            {
                                props.isLoading ?
                                    <LoadingIconAnimation src={LoadingIcon} alt="icon" />
                                    :
                                    <Tooltip
                                        title={t('tooltip.send_message.title')}
                                        description={t('tooltip.send_message.description')}
                                    >
                                        <img src={SendRightIcon} alt="icon" width="25px" height="25px" onClick={handleSendMessage} />
                                    </Tooltip>
                            }
                    </RowWidget>
                </RowWidget>

                {/* {
                    caseData?.agent_id &&
                        <SkillContainer>
                            {
                                agent?.skills
                                    .slice(0, window.innerWidth <= 535 ? undefined : showAllSkills ? undefined : 4)
                                    .map((skill: any, index: any) => (
                                        <Tooltip
                                            key={index}
                                            title={t('tooltip.skill.title')}
                                            description={t('tooltip.skill.title')}
                                        >
                                            <Container $backgroundColor="#FFF" $padding="8px 10px" $borderRadius="8px" $cursor="pointer" onClick={() => !skills.includes(skill.name) && setSkills((prevSkills) => [...prevSkills, skill.name])}>
                                                <Text $fontSize="14px" style={{ whiteSpace: 'nowrap' }}>{skill.name}</Text>
                                            </Container>
                                        </Tooltip>
                                    ))
                            }

                            <Text $color="#153968" $margin="0 0 0 auto" onClick={() => setShowAllSkills(!showAllSkills)} style={{ cursor: 'pointer', whiteSpace: 'nowrap', }} className="responsive-text">
                                {showAllSkills ? "<" : "more skill >"}
                            </Text>
                        </SkillContainer>
                } */}
            </SendMessageCardStyle>
        </SendMessageCardContainer>
    );
};

export default SendMessageCard;