import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Text from "../../shared/lib/widgets/Text";
import RowWidget from "../../shared/lib/widgets/RowWidget";

import Popup from "./Popup"

import Input from "../Inputs/Input";

import SecondaryButton from "../Buttons/SecondaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";


interface DeleteAccountPopupProps {
    onSubmit: () => void;
    onClose: () => void;
}

const DeleteAccountPopup: React.FC<DeleteAccountPopupProps> = ({ onSubmit, onClose }) => {
    const { t } = useTranslation();
    
    const [deleteAccount, setDeleteAccount] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = () => {
        if (!deleteAccount)
            return setError(t('messages.info.type_delete'));

        if (deleteAccount !== 'ELIMINA' && deleteAccount !== 'DELETE')
            return setError(t('messages.info.typing_error'));
        
        onSubmit();
    }
    
    return (
        <Popup title={t('settings.personal_info.popup.title')} onClose={onClose}>
            <RowWidget $flexDirection='column'>
                <Text $fontSize="24px" $fontWeight="bold" $margin="0 0 8px 0">{t('settings.personal_info.popup.subtitle')}</Text>
                <Text $fontSize="16px" $fontWeight="400" $margin="0 0 24px 0" $color="#46454D">{t('settings.personal_info.popup.description')}</Text>

                <Input 
                    label={t('input.delete_account_label')}
                    value={deleteAccount}
                    onChange={(e) => setDeleteAccount(e.target.value)}
                    errorMessage={error}
                />

                <RowWidget $justifyContent="space-between"  $margin="16px 0 0 0" $gap="16px">
                    <SecondaryButton 
                        onClick={onClose}
                        title={t('button.cancel')}
                        $width='120px'
                        $borderColor='#E9E9EA'
                    />
                    <PrimaryButton 
                        type="red"
                        onClick={handleSubmit}
                        title={t('button.delete_account')}
                        $width='160px'
                    />
                </RowWidget>
            </RowWidget>
        </Popup>
    );
};

export default DeleteAccountPopup;