import React from "react";

import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import MarketSuccessIcon from '../../assets/MarketSuccessIcon.svg';

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";

import { Agent } from "../../entities/Agents/models/types/Agent.types";


const AgentCardContainer = styled.div`
    background-color: #FDFDFE;
    padding: 12px;
    border-radius: 12px;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 6px 16px 0px #201F2914;
    }
`;

const PaymentButton = styled.div<{ isPaid: boolean }>`
    background-color: ${(props) => props.isPaid ? '#E9ECF1' : '#FFF'};
    border: 1px solid ${(props) => props.isPaid ? '#6D7A8B' : '#153968'};
    padding: 10px 16px;
    border-radius: 8px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface AgentCardProps {
    agentInfo: Agent;
    isPaid: boolean;
}

const AgentCard: React.FC<AgentCardProps> = ({ agentInfo, isPaid }) => {
    const navigate = useNavigate();
    
    return (
        <AgentCardContainer onClick={() => navigate(agentInfo.id)}>
            <RowWidget $justifyContent="space-between">
                <RowWidget $alignItems="center">
                    <img 
                        src={agentInfo.profileImage}
                        alt="agent" 
                        style={{
                            borderRadius: '50%',
                            objectFit: 'cover',
                            width: '50px',
                            height: '50px',
                        }}
                    />

                    <Container $width="8px" />

                    <Link to={agentInfo.id} style={{ textDecoration: 'none' }}>
                        <Text $fontWeight="bold" $fontSize="18px">{agentInfo.name}</Text>
                    </Link>
                </RowWidget>

                <RowWidget $alignItems="end" $height="fit-content">
                    <Text $fontSize="22px" $fontWeight="bold" $color="#201F29">€{agentInfo.price}</Text>

                    <Container $width="4px" />
                    
                    <Text $fontSize="14px" $fontWeight="400" $color="#A4A3A7" $margin="0 0 4px 0">/al mese</Text>
                </RowWidget>
            </RowWidget>

            <Container $height="24px" />

            <RowWidget $alignItems="center" $justifyContent="space-between">
                <RowWidget $gap="10px">
                    {
                        agentInfo.skills.slice(0, 2).map((item, index) =>
                            <Container key={index} $padding="6px 12px" $borderRadius="40px" $backgroundColor="#F6F7F9">
                                <Text $fontSize="14px" $color="#201F29">{item.name}</Text>
                            </Container>
                        )
                    }
                </RowWidget>
                
                <PaymentButton isPaid={isPaid}>
                    {
                        isPaid &&
                        <img src={MarketSuccessIcon} alt="icon" style={{ width: '16px', height: '16px', marginRight: '8px' }} />
                    }
                    <Text $fontSize="14px" color={isPaid ? "#6D7A8B" : '#153968'} $fontWeight="bold">{isPaid ? 'Iscritto' : 'Iscriviti'}</Text>
                </PaymentButton>
            </RowWidget>
        </AgentCardContainer>
    );
}

export default AgentCard;