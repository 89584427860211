import { useState } from "react";

import { toast } from "react-toastify";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import MoreIcon from '../../../assets/more_icon.svg';
import LessIcon from '../../../assets/less_icon.svg';
import SupportAnswerIcon from '../../../assets/support_answer_icon.svg';

import Input from "../../../components/Inputs/Input";
import DropDown from "../../../components/DropDown/DropDown";
import SubHeader from "../../../components/SubHeader/SubHeader";
import InputTextArea from "../../../components/Inputs/InputTextArea";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

import Text from "../../../shared/lib/widgets/Text";
import Container from "../../../shared/lib/widgets/Container";
import RowWidget from "../../../shared/lib/widgets/RowWidget";


const SupportQuestionContainer = styled.div`
    margin: 50px auto;
    width: 60%;
    @media (max-width: 1024px) {
        width: 95%;
    }
`;

const FooterStyle = styled.div`
    width: 100%;
    padding: 5% 10%; 
    background-color: #F9FAFC;
    display: flex;
    gap: 10%;
    @media (max-width: 1440px) {
        padding: 5%;
    }
    @media (max-width: 1024px) {
        display: block;
    }
`;

const BrowseButton = styled.label`
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    color: #4158E3;
    font-weight: bold;
    font-size: 16px;
    outline: none;
    border: none;
    background-color: transparent;
    &:hover {
        color: #2E3E9F;
    }
    &:active {
        color: #28368A;
    }
    &:focus {
        color: #28368A;
    }
`;

const SupportAndAssitance = () => {
    const { t } = useTranslation();
    
    const handleFileChange = (e: any) => {
        toast.info(t('messages.info.service_not_available'));
        // const selectedFiles = e.target.files;
        // if (selectedFiles && selectedFiles.length > 0) {
        //     const newFiles = Array.from(selectedFiles);
        //     setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        // }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        toast.info(t('messages.info.service_not_available'));

        // const droppedFiles = e.dataTransfer.files;
        // if (droppedFiles.length > 0) {
        //     const newFiles = Array.from(droppedFiles);
        //     setFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
        // }
    };
    return (
        <>
            <SubHeader title="Back" to={-1} border="1px solid #E4E4E4"/>

            <Text $textAlign="center" $fontSize="38px" $fontWeight="bold" $margin="64px 0 12px 0">{t('affiliate.support_and_assistance.title')}</Text>
            
            <Text $textAlign="center">{t('affiliate.support_and_assistance.subtitle')}</Text>

            <SupportQuestionContainer>
                <SupportQuestion 
                    title='Why should I join LegalAssits Affiliate program?' 
                    description='The application uses artificial intelligence to analyze the content of the document and provide a chat interface for users to ask questions get answers.' 
                />
                <SupportQuestion 
                    title='Who can join the Affiliate program?' 
                    description='The application uses artificial intelligence to analyze the content of the document and provide a chat interface for users to ask questions get answers.' 
                />
                <SupportQuestion 
                    title='Can I become a LegalAssits Affiliate partner lorem ipsum?' 
                    description='The application uses artificial intelligence to analyze the content of the document and provide a chat interface for users to ask questions get answers.' 
                />
                <SupportQuestion 
                    title='Can I change my personal info after sign up?' 
                    description='The application uses artificial intelligence to analyze the content of the document and provide a chat interface for users to ask questions get answers.' 
                />
                <SupportQuestion 
                    title='How do I make money with  LegalAssits Affiliate program?' 
                    description='The application uses artificial intelligence to analyze the content of the document and provide a chat interface for users to ask questions get answers.' 
                />
            </SupportQuestionContainer>

            <FooterStyle>
                <Container $width="100%">
                    <img 
                        src={SupportAnswerIcon} 
                        alt="icon" 
                    />
                    
                    <Text $margin="20px 0" $fontSize="42px" $fontWeight="bold" style={{ letterSpacing: '1px' }}>{t('affiliate.support_and_assistance.footer.title')}</Text>

                    <Text>{t('affiliate.support_and_assistance.footer.subtitle')}</Text>
                </Container>

                <Container $width="100%" $backgroundColor="#FFF" $borderRadius="12px" $padding="20px">
                    <DropDown 
                        label="We're here to help! Tell us more."
                        value="Physical Person"
                        onChange={() => {}}
                        menuItems={['Physical Person']}
                    />
                    
                    <Container $height="16px" />
                    
                    <Input 
                        label={t('input.user_email_label')}
                        placeholder={t('input.user_email_placeholder')}
                    />

                    <Container $height="16px" />

                    <Input 
                        label={t('input.subject_label')}
                        placeholder={t('input.subject_label')}
                    />

                    <Container $height="16px" />

                    <InputTextArea 
                        label={t('input.description_label')}
                        placeholder={t('input.description_request_placeholder')}
                    />

                    <Container $height="16px" />

                    <Text $fontSize="14px">{t('input.attachments_label')}</Text>

                    <Container $height="6px" />

                    <Container
                        $border="1px dashed #6BB5FF"
                        $padding="30px 20px"
                        $borderRadius="6px"
                        onDrop={handleDrop}
                        onDragOver={(event) => event.preventDefault()}
                    >
                        <input
                            type="file"
                            hidden
                            id="browse"
                            onChange={handleFileChange}
                            accept=".pdf,.docx,.pptx,.txt,.xlsx"
                            multiple
                        />

                        <RowWidget $justifyContent="center" $gap="4px">
                            <BrowseButton htmlFor="browse">Upload file</BrowseButton>
                            <Text $color="#7E7D83">or drop files here</Text>
                        </RowWidget>
                    </Container>

                    <Container $height="32px" />

                    <PrimaryButton 
                        $width="160px"
                        onClick={() => {}}
                        title="Submit"
                    />
                </Container>
            </FooterStyle>
        </>
    );
}

const SupportQuestion = ({ title, description }: any) => {
    const [isOpen, setIsOpen] = useState(false)
    
    return (
        <Container onClick={() => setIsOpen(!isOpen)} $width="100%" $backgroundColor="#F6F7F9" $borderRadius="12px" $padding="20px 16px" $margin="0 0 12px 0" $cursor="pointer">
            <RowWidget $alignItems="center" $justifyContent="space-between">
                <Text $fontSize="20px">{title}</Text>

                <img 
                    src={isOpen ? LessIcon : MoreIcon} 
                    alt="icon" 
                    style={{
                        userSelect: 'none',
                        width: '20px',
                        height: '20px',
                    }}
                />
            </RowWidget>

            {
                isOpen &&
                    <Text $margin="16px 0 0 0">{description}</Text>
            }
        </Container>
    );
}


export default SupportAndAssitance;