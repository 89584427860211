import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { academyList } from '../shared/consts/academy_mock';

import FAQ from '../components/PageContent/Academy/FAQ';

import AcademyCard from '../components/Cards/AcademyCard';

import Text from '../shared/lib/widgets/Text';
import RowWidget from '../shared/lib/widgets/RowWidget';
import Container from '../shared/lib/widgets/Container';


const Academy: React.FC = () => {
    const { t } = useTranslation();
    
    const navigate = useNavigate();

    return (
        <Container>
            <Container $height='64px' />

            <Text $fontSize='32px' $fontWeight='bold' $textAlign='center'>{t('academy.title')}</Text>
            
            <Container $height='10px' />
            
            <Text $color='#908F94' $fontWeight='400' $textAlign='center'>{t('academy.subtitle')}</Text>

            <Container $height='50px' />

            <RowWidget $justifyContent='center' $flexWrap='wrap' $gap='20px'>
                {
                    academyList.map((item, index) =>
                        <AcademyCard 
                            key={index}
                            onClick={() => navigate(item.path)}
                            icon={item.icon}
                            title={item.title}
                            backgroundColor={item.backgroundColor}
                        />
                    )
                }
            </RowWidget>

            <Container $height='50px' />

            <FAQ />
            
            <Container $height='32px' />
        </Container>
    );
};

export default Academy;

