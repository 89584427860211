import React, { useState } from "react";

import SuccessIcon from '../../assets/success.svg';

import Popup from "./Popup";

import Input from "../Inputs/Input";

import PayoutToggle from "../Toggles/PayoutToggle";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


interface SelectPayoutMethodProps {
    onClose: () => void;
    onSubmit: () => void;
}

const SelectPayoutMethodPopup: React.FC<SelectPayoutMethodProps> = ({ onClose, onSubmit }) => {
    const [amount, setAmount] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;

        if (!/^[\d,]*$/.test(inputValue)) return;

        if (inputValue.includes(',')) {
            const parts = inputValue.split(',');
            if (parts[1]?.length > 2) {
                inputValue = `${parts[0]},${parts[1].slice(0, 2)}`;
            }
        }

        setAmount(inputValue);
    };
    
    return (
        <Popup title='Select payout method' onClose={onClose} width='600px'>
            <Container>
                <Input 
                    label='Payout amount (EUR)'
                    value={amount.toString()}
                    onChange={handleInputChange}
                    placeholder='0.00'
                />

                <Container $height='6px' />

                <Text $color='#A4A3A7' $fontSize='14px'>€0,00 available for payout</Text>

                <Container $height='32px' />
                
                <Text $color='#46454D' $fontSize='14px'>Where you'd like us to send your money:</Text>

                <Container $height='12px' />

                <PayoutToggle />

                <Container $height='32px' />

                {/* <RowWidget $justifyContent='space-between' $alignItems='center' $gap='8px'>
                    <Text $fontWeight='bold'>Add new payment method</Text>

                    <SwitchToggle label={false ? 'On' : 'Off'} isActive={false} onToggle={() => {}} />
                </RowWidget>

                <Container $height='32px' />

                {
                    false &&
                        <>
                            <Text $color='#46454D' $fontSize='14px'>Where you'd like us to send your money:</Text>

                            <Container $height='12px' />

                            <PayloutToggle />

                            <Container $height='32px' />
                        </>
                } */}

                <PrimaryButton 
                    onClick={() => {onClose(); onSubmit();}}
                    title='Continue'
                    $width='180px'
                />
            </Container>
        </Popup>
    );
}

interface AccountDetailsProps {
    onClose: () => void;
    onBack: () => void;
    onSubmit: () => void;
}

const AccountDetailsPopup: React.FC<AccountDetailsProps> = ({ onClose, onBack, onSubmit }) => {
    // const [form, setForm] = useState('');
    
    return (
        <Popup title='Enter your bank account details' onClose={onClose} width='600px'>
            <Container>
                <Input 
                    label='Bank Name'
                    placeholder='Enter the name of your bank'
                    value=''
                    onChange={() => {}}
                />

                <Container $height='20px' />

                <RowWidget $gap='16px'>
                    <Input 
                        label='First Name'
                        placeholder='Enter your first name'
                        value=''
                        onChange={() => {}}
                    />

                    <Input 
                        label='Last Name'
                        placeholder='Enter your last name'
                        value=''
                        onChange={() => {}}
                    />
                </RowWidget>

                <Container $height='20px' />

                <Input 
                    label='Account Number'
                    placeholder='Enter your professional address'
                    value=''
                    onChange={() => {}}
                />

                <Container $height='20px' />

                <RowWidget $gap='16px'>
                    <Input 
                        label='Account Number'
                        placeholder='Ex. 00000000'
                        value=''
                        onChange={() => {}}
                    />

                    <Input 
                        label='Routing/Sort Code'
                        placeholder='Ex. 0000'
                        value=''
                        onChange={() => {}}
                    />
                </RowWidget>
                
                <Container $height='20px' />

                <Input 
                    label='Bank Address (optional for international transfers)'
                    placeholder='Enter bank address'
                    value=''
                    onChange={() => {}}
                />

                <Container $height='20px' />

                <RowWidget $alignItems='center' $gap='8px'>
                    <input 
                        type='checkbox'
                        style={{
                            width: '20px',
                            height: '20px',
                        }}
                    />
                
                    <Text $color='#46454D' $fontSize='14px'>Save this payout information for future withdrawls</Text>
                </RowWidget>

                <Container $height='32px' />

                <RowWidget $justifyContent='space-between' $gap='16px'>
                    <SecondaryButton 
                        onClick={() => {
                            onClose();
                            onBack();
                        }}
                        title='Back'
                        $width='120px'
                        $borderColor='#DCDCDD'
                    />
                
                    <PrimaryButton 
                        onClick={() => {
                            onClose();
                            onSubmit();
                        }}
                        title='Next'
                        $width='180px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
}

interface PayPalDetailsProps {
    onClose: () => void;
    onBack: () => void;
    onSubmit: () => void;
}

const PayPalDetailsPopup: React.FC<PayPalDetailsProps> = ({ onClose, onBack, onSubmit }) => {
    return (
        <Popup title='Enter your PayPal account details' onClose={onClose} width='600px'>
            <Container>

                <RowWidget $gap='16px'>
                    <Input 
                        label='PayPal Email Address'
                        placeholder='Enter your PayPal email address'
                        value=''
                        onChange={() => {}}
                    />

                    <Input 
                        label='Confirm PayPal Email'
                        placeholder='Enter your PayPal email address'
                        value=''
                        onChange={() => {}}
                    />
                </RowWidget>

                <Container $height='20px' />

                <RowWidget $alignItems='center' $gap='8px'>
                    <input 
                        type='checkbox'
                        style={{
                            width: '20px',
                            height: '20px',
                        }}
                    />
                
                    <Text $color='#46454D' $fontSize='14px'>Save this payout information for future withdrawls</Text>
                </RowWidget>

                <Container $height='32px' />

                <RowWidget $justifyContent='space-between' $gap='16px'>
                    <SecondaryButton 
                        onClick={() => {
                            onClose();
                            onBack();
                        }}
                        title='Back'
                        $width='120px'
                        $borderColor='#DCDCDD'
                    />
                
                    <PrimaryButton 
                        onClick={() => {
                            onClose();
                            onSubmit();
                        }}
                        title='Next'
                        $width='180px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
}

interface ConfirmPayoutWithdrawalProps {
    onClose: () => void;
    onBack: () => void;
    onSubmit: () => void;
}

const ConfirmPayoutWithdrawalPopup: React.FC<ConfirmPayoutWithdrawalProps> = ({ onClose, onBack, onSubmit }) => {
    // const [form, setForm] = useState('');
    
    return (
        <Popup title='Confirm your withdrawal' onClose={onClose} width='600px'>
            <Container>
                <RowWidget $gap='8px'>
                    <Text $fontWeight='bold'>Method:</Text>
                    <Text>Bank Transfer</Text>
                </RowWidget>

                <Container $height='20px' />

                <RowWidget $gap='8px'>
                    <Text $fontWeight='bold'>Amount:</Text>
                    <Text>0,00</Text>
                </RowWidget>

                <Container $height='20px' />

                <Text $fontWeight='bold' $fontSize='14px'>Payout Information:</Text>

                <Container $height='8px' />

                <Container $backgroundColor='#FFF' $borderRadius='8px' $border='1px solid #E9E9EA' $padding='16px'>
                    <RowWidget $gap='8px'>
                        <Text $color='#7E7D83' $fontWeight='bold'>Bank Name:</Text>
                        <Text>XYZ</Text>
                    </RowWidget>

                    <Container $height='8px' />

                    <RowWidget $gap='8px'>
                        <Text $color='#7E7D83' $fontWeight='bold'>Account Number:</Text>
                        <Text>XXXX1234</Text>
                    </RowWidget>
                </Container>

                <Container $height='32px' />

                <RowWidget $justifyContent='space-between' $gap='16px'>
                    <SecondaryButton 
                        onClick={() => {
                            onClose();
                            onBack();
                        }}
                        title='Back'
                        $width='120px'
                        $borderColor='#DCDCDD'
                    />
                
                    <PrimaryButton 
                        onClick={() => {
                            onClose();
                            onSubmit();
                        }}
                        title='Confirm'
                        $width='180px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
}

interface SuccessWithdrawlPopupProps {
    onClose: () => void;
}

const SuccessWithdrawlPopup: React.FC<SuccessWithdrawlPopupProps> = ({ onClose }) => {
    return (
        <Popup title='Success!' onClose={onClose} width='600px'>
            <Container>
                <RowWidget $justifyContent='center'>
                    <img 
                        src={SuccessIcon}
                        alt="key" 
                        style={{ 
                            width: '80px', 
                            height: '80px' 
                        }}
                    />
                </RowWidget>

                <Container $height='32px' />

                <Text $fontWeight='bold' $fontSize='32px' $textAlign='center'>Your withdrawal request has been submitted</Text>

                <Container $height='16px' />

                <Text $textAlign='center' $fontSize='14px'>Your withdrawal is being processed. You should receive the founds in 3-5 business days.</Text>

                <Container $height='32px' />

                <RowWidget $justifyContent='center'>
                    <PrimaryButton 
                        onClick={onClose}
                        title='Done'
                        $width='180px'
                    />
                </RowWidget>
            </Container>
        </Popup>
    );
}

export {
    SelectPayoutMethodPopup,
    AccountDetailsPopup,
    PayPalDetailsPopup,
    ConfirmPayoutWithdrawalPopup,
    SuccessWithdrawlPopup,
}