import React, { useState } from "react";

import styled from "styled-components";
import { toast } from "react-toastify";
import { Trans, useTranslation } from 'react-i18next';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from "firebase/auth";

import { auth } from "../../../configs/firebase-config";

import GoogleIcon from "../../../assets/google.svg";
import FacebookIcon from "../../../assets/facebook.svg";

import PrimaryButton from "../../Buttons/PrimaryButton";

import InputPassword from "../../Inputs/InputPassword";

import SwitchToggle from "../../Toggles/SwitchToggle";

import DropDown from "../../DropDown/DropDown";

import Text from "../../../shared/lib/widgets/Text";
import RowWidget from "../../../shared/lib/widgets/RowWidget";
import Container from "../../../shared/lib/widgets/Container";
import SecondaryButton from "../../Buttons/SecondaryButton";

const ChangePasswordStyled = styled.div`
    width: 500px;
    margin-bottom: 20px;
    @media (max-width: 645px) {
        width: 100%;
    }
`;

const ChangePassword: React.FC = () => {
    const { t } = useTranslation();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const [providedAuth,] = useState(auth!.currentUser?.providerData[0]?.providerId);

    const [errors, setErrors] = useState({
        currentPassword: '',
        newPassword: '',
        repeatNewPassword: '',
    });

    const validate = () => {
        const newErrors = {
            currentPassword: '',
            newPassword: '',
            repeatNewPassword: '',
        };

        if (!currentPassword)
            newErrors.currentPassword = t('messages.errors.input.password_wrong');

        if (newPassword.length < 8) 
            newErrors.newPassword = t('messages.errors.input.password');

        if (confirmNewPassword.length < 8) 
            newErrors.repeatNewPassword = t('messages.errors.input.password');

        if (newPassword !== confirmNewPassword) {
            newErrors.newPassword = t('messages.errors.input.password_match');
            newErrors.repeatNewPassword = t('messages.errors.input.password_match');
        }

        setErrors(newErrors)
        return Object.values(newErrors).some(error => error);
    };
    
    const handleChangePassword = async (e: any) => {
        e.preventDefault();

        const validationErrors = validate();
        if (validationErrors)
            return;

        try {
            const firebaseUser = auth.currentUser;
            if (!firebaseUser || !firebaseUser.email) {
                toast.error(t('messages.errors.somthing_wrong'));
                return;
            }
            
            const credential = EmailAuthProvider.credential(
                firebaseUser.email,
                currentPassword,
            );
            
            try {
                await reauthenticateWithCredential(firebaseUser, credential);
            } catch (e) {
                if (e instanceof Error && e.message.includes('auth/too-many-requests'))
                    return toast.info(t('messages.info.too_many_requests'));
                return setErrors((prevErrors) => ({
                    ...prevErrors,
                    currentPassword: t('messages.errors.input.password_wrong')
                }));
            }

            await updatePassword(auth.currentUser!, newPassword);

            toast.success(t('messages.success.password_changed'));
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        } catch (error) {
            toast.error(t('messages.errors.somthing_wrong'));
        }
    };

    const [selectedMethod, setSelectedMethod] = useState(t('settings.security.two_factor_method_placeholder'));
    const [isTwoFactorEnabled, setTwoFactorEnabled] = useState(false);


    const handleMethodChange = (value: string) => {
        setSelectedMethod(value);
    };

    const handleToggleTwoFactor = () => {
        setTwoFactorEnabled(!isTwoFactorEnabled);
    };
    return (
        <ChangePasswordStyled>
            <Text $fontSize="20px" $fontWeight="bold">{t('settings.security.title')}</Text>

            {
                providedAuth === 'password' ?
                    <>
                        <Container $height="32px" />

                        <form>
                            <InputPassword
                                label={t('input.current_password_label')}
                                value={currentPassword}
                                onChange={(e: any) => setCurrentPassword(e.target.value)}
                                errorMessage={errors.currentPassword}
                            />

                            <Container $height="16px" />
                            
                            <InputPassword
                                label={t('input.new_password_label')}
                                value={newPassword}
                                onChange={(e: any) => setNewPassword(e.target.value)}
                                errorMessage={errors.newPassword}
                            />

                            <Container $height="16px" />

                            <InputPassword
                                label={t('input.confirm_new_password_label')}
                                value={confirmNewPassword}
                                onChange={(e: any) => setConfirmNewPassword(e.target.value)}
                                errorMessage={errors.repeatNewPassword}
                            />
                        </form>

                        <Container $height="20px" />

                        <PrimaryButton 
                            onClick={handleChangePassword}
                            title={t('button.update_password')}
                            $width="180px"
                        />
                    </>
                    :
                    <>
                        <Container $height="12px" />
                            <Text $color="#908F94">
                                <Trans
                                    t={t}
                                    i18nKey="settings.security.linked_account"
                                    values={{ type: providedAuth === 'google.com' ? 'Google' : 'Facebook' }}
                                    components={{ b: <span style={{ fontWeight: 'bold' }} /> }}
                                />
                            </Text>

                        <Container $height="20px" />

                        <SecondaryButton 
                            onClick={() => {
                                const url = providedAuth === 'google.com' 
                                    ? 'https://accounts.google.com/' 
                                    : 'https://www.facebook.com/';
                                window.open(url, '_blank');
                            }}
                            leading={providedAuth === 'google.com' ? GoogleIcon : FacebookIcon}
                            title={t('settings.security.manage_linked_account', { type: 'Google' })}
                            $width="80%"
                            $borderColor="#E9E9EA"
                        />
                    </>
            }

            <Container $height="40px" />

            <Text $fontSize="20px" $fontWeight="bold">{t('settings.security.two_factor_title')}</Text>

            <Container $height="20px" />

            <RowWidget $alignItems="center" $justifyContent="space-between">
                <Text $color="#6b6969">{t('settings.security.two_factor_subtitle')}</Text>
                <SwitchToggle label={isTwoFactorEnabled ? 'On' : 'Off'} isActive={isTwoFactorEnabled} onToggle={handleToggleTwoFactor} />
            </RowWidget>

            <Container $height="16px" />

            {
                isTwoFactorEnabled &&
                    <DropDown 
                        label={t('settings.security.two_factor_method_label')}
                        positionTop={true}
                        dropDownName={selectedMethod}
                        value={selectedMethod} 
                        onChange={handleMethodChange} 
                        menuItems={[t('settings.security.methods.email'), t('settings.security.methods.app'), t('settings.security.methods.sms')]} 
                    />
            }
        </ChangePasswordStyled>
    )
}
export default ChangePassword;