import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { $api } from "../../shared/api/api";

import { isEmail } from "../../shared/utils/validators";

import CardIcon from '../../assets/CardIcon.svg';
import EmailIcon from '../../assets/EmailIcon.svg';
import AllertIcon from '../../assets/AllertIcon.svg';

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";


interface SubscriptionPaymentFormProps {
    onSubmit: () => void;
    onClose: () => void;
    price: string
}

const SubscriptionPaymentForm: React.FC<SubscriptionPaymentFormProps> = ({ onSubmit, onClose, price }) => {
    const { t } = useTranslation();
    
    const stripe = useStripe();
    const elements = useElements();

    const [email, setEmail] = useState('');
    const [showError, setShowError] = useState('');
    const [isElementsReady, setIsElementsReady] = useState(false);

    useEffect(() => {
        if (elements) {
            setIsElementsReady(true);
        }
    }, [elements]);

    useEffect(() => {
        if (!isElementsReady) return;

        const cardNumberElement = elements!.getElement(CardNumberElement);
        const cardExpiryElement = elements!.getElement(CardExpiryElement);
        const cardCvcElement = elements!.getElement(CardCvcElement);

        if (!cardNumberElement || !cardExpiryElement || !cardCvcElement) {
            toast.error(t('messages.errors.somthing_wrong'));
            return;
        }

        const handleChange = (event: any) => {
            if (event.error)
                setShowError(event.error.message);
            else
                setShowError('');
        };

        cardNumberElement.on('change', handleChange);
        cardExpiryElement.on('change', handleChange);
        cardCvcElement.on('change', handleChange);

        return () => {
            cardNumberElement.off('change', handleChange);
            cardExpiryElement.off('change', handleChange);
            cardCvcElement.off('change', handleChange);
        };
    }, [isElementsReady, elements, t]);
    
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            toast.error('Stripe error!');
            return;
        }

        if (!isEmail(email))
            return setShowError(t('messages.errors.input.email'));

        const data = await $api.post(`/create-payment-intent`, { price: price })
        .then(response => response.data)
        .catch(error => toast.error(error.message))

        if (!data.clientSecret)
            return toast.error(t('messages.errors.payment'))

        const cardElement = elements.getElement(CardNumberElement)

        if (!cardElement) 
            return toast.error(t('messages.errors.somthing_wrong'));

        try {
            const paymentMethodReq = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
                billing_details: {
                    email,
                },
            });

            if (paymentMethodReq!.error) {
                toast.error(paymentMethodReq!.error.message);
                return;
            }


            const confirmedCardPayment = await stripe.confirmCardPayment(data.clientSecret, {
                payment_method: paymentMethodReq!.paymentMethod.id
            });

            if (confirmedCardPayment.error) {
                toast.error(confirmedCardPayment.error.message);
                return;
            }

            toast.success(t('messages.success.payment_successful'))
            onSubmit();
        } catch (err: any) {
            toast.error(err.message)
        }
    };

    return (
        <>
            {
                showError &&
                    <Container $backgroundColor="#FFEEEE" $padding="12px" $borderRadius="6px" $margin="0 0 16px 0">
                        <RowWidget $gap="12px" $alignItems="center">
                            <img src={AllertIcon} alt="icon" height="18px" />

                            <Text $color="#9C3232">{showError}</Text>
                        </RowWidget>
                    </Container>
            }
        
            <Container $width="100%" $border={`1px solid ${showError ? '#FF5252' : '#E9E9EA'}`} $borderRadius="12px" style={{ overflow: 'hidden' }}>
                <Container $padding="16px" $backgroundColor={showError ? '#FFEEEE' : '#FFF'} style={{ borderBottom: `1px solid ${showError ? '#FF5252' : '#E9E9EA'}` }}>
                    <Text $fontSize="14px" $fontWeight="400" $color='rgba(70, 69, 77, 1)'>{t('input.card_email_placeholder')}</Text>

                    <Container $height="6px" />

                    <RowWidget $alignItems="center">
                        <img src={EmailIcon} alt="icon" width='24px' height='24px' />
                        <input style={{ 
                            width: '100%', 
                            outline: 'none', 
                            border: 'none', 
                            padding: '10px 16px', 
                            fontSize: '16px', 
                            backgroundColor: showError ? '#FFEEEE' : '#FFF',
                        }} type="text" 
                        value={email} 
                        onChange={(e:any) => setEmail(e.target.value)}
                        placeholder="email@gmail.com" />
                    </RowWidget>
                </Container>

                <Container $padding="16px" $backgroundColor={showError ? "#FFEEEE" : '#FFF'}>
                    <Text $fontSize="14px" $fontWeight="400" $color='rgba(70, 69, 77, 1)'>{t('input.card_details_placeholder')}</Text>

                    <Container $height="6px" />

                    <RowWidget $alignItems="center" $padding="10px 0">
                        <img src={CardIcon} alt="icon" width='24px' height='24px' />
                        
                        <Container $width="16px" />
                        
                        <Container $width="100%">
                            <CardNumberElement />
                        </Container>

                        <Container $width="150px" $margin="0 16px">
                            <CardExpiryElement />
                        </Container>
                        
                        <Container $width="120px">
                            <CardCvcElement />
                        </Container>
                    </RowWidget>
                </Container>
            </Container>

            <Container $height="32px" />

            <Text $fontWeight="bold" $textAlign="right" $fontSize="28px">{t('upgrade_plan.popup.subscribe.price')}: €{price}</Text>

            <Container $height="16px" />
            
            <RowWidget $margin="0 0 0 16%" $justifyContent="end">
                <Text $textAlign="right" $color="#A4A3A7" $fontSize="14px">{t('upgrade_plan.popup.subscribe.description1')}€{price}{t('upgrade_plan.popup.subscribe.description2')}</Text>
            </RowWidget>
            
            <Container $height="24px" />

            <RowWidget $justifyContent="end" $alignItems="center" $gap="16px">
                <SecondaryButton 
                    onClick={onClose}
                    title={t('button.cancel')}
                    $borderColor='#E9E9EA'
                    $width='120px'
                />
                <PrimaryButton 
                    onClick={handleSubmit}
                    title={t('button.confirm_purchase')}
                    $width='180px'
                />
            </RowWidget>
        </>
    );
}

export default SubscriptionPaymentForm;