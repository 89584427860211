import React, { useState } from "react";

import { toast } from "react-toastify";
import styled from "styled-components";

import FAQContactPopup from "../../Popups/FAQContactPopup";

import FAQIcon from "../../../assets/FAQ.svg"
import DropDownIcon from "../../../assets/DropDown.svg"

import TextButton from "../../Buttons/TextButton";

import Text from "../../../shared/lib/widgets/Text";
import RowWidget from "../../../shared/lib/widgets/RowWidget";
import Container from "../../../shared/lib/widgets/Container";
import { faqTexts } from "../../../shared/consts/academy_mock";
import { useTranslation } from "react-i18next";


const FAQHeader = styled.div`
    border-bottom: 1px solid #e9e9ea;
    padding: 32px;
    display: flex;
    align-items: center;
    gap: 32px;
`;

const FAQAnswers = styled.div`
    flex: 1;
    border-left: 1px solid #e9e9ea;
    padding: 20px 32px;
`;

const FAQ: React.FC = () => {
    const { t } = useTranslation();
    
    const [contactUsVisible, setContactUsVisible] = useState(false);
    
    return (
        <RowWidget $justifyContent="center">
            {
                contactUsVisible &&
                    <FAQContactPopup 
                        onSubmit={() => toast.info(t('messages.info.service_not_available'))}
                        onClose={() => setContactUsVisible(false)}
                    />
            }

            <Container $width="1024px" $backgroundColor="#F6F7F9" $borderRadius="12px">
                <FAQHeader>
                    <img src={FAQIcon} alt="icon" width="72px" />
                    
                    <Container>
                        <Text $fontSize="23px" $fontWeight="bold">{t('academy.faq.title')}</Text>

                        <Container $height="8px" />
                        
                        <RowWidget $gap="6px" $flexWrap="wrap">
                            <Text $color="#778ca7">{t('academy.faq.description1')}</Text>
                            <TextButton 
                                onClick={() => setContactUsVisible(true)}
                                title={t('button.contact')}
                                fontWeight="bold"
                            />
                            <Text $color="#778ca7">{t('academy.faq.description2')}</Text>
                        </RowWidget>
                    </Container>
                </FAQHeader>

                <RowWidget $flexWrap="wrap">
                    <Container $padding="32px" style={{ textWrap: 'nowrap' }}>
                        {
                            (t('academy.faq.faq_list', { returnObjects: true }) as string[]).map((item, index) =>
                                <Text key={index} $padding="12px">{item}</Text>
                            )
                        }
                    </Container>

                    <FAQAnswers>
                        {
                            faqTexts.map((item, index) => 
                                <React.Fragment key={index}>
                                    <FAQQuestion item={item} />
                                    {
                                        index < faqTexts.length - 1 &&
                                        <Container $width="100%" $backgroundColor="#e9e9ea" $height="1px" />
                                    }
                                </React.Fragment>
                            )
                        }
                    </FAQAnswers>
                </RowWidget>
            </Container>
        </RowWidget>
    );
}

export default FAQ;


interface FAQQuestionProps {
    item: string;
}

const FAQQuestion: React.FC<FAQQuestionProps> = (props) => {
    const { t } = useTranslation();
    
    const [isVisible, setIsVisible] = useState(false);

    return (
        <Container onClick={() => setIsVisible(!isVisible)} style={{ cursor: 'pointer' }}>
            <RowWidget $justifyContent="space-between" $alignItems="center" $width="100%" $padding="20px 0">
                <Text $fontSize="18px">{t(`academy.faq.questions.${props.item}.title`)}</Text>
                
                <img src={DropDownIcon} alt="icon" width="18px" height="18px" style={{ rotate: isVisible? '180deg' : '0deg' }} />
            </RowWidget>

            {
                isVisible &&
                    <Text $fontSize="14px" $color="#46454d" $padding="0 0 20px 0">{t(`academy.faq.questions.${props.item}.description`)}</Text>
            }
        </Container>
    );
}