import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import SubHeader from '../../SubHeader/SubHeader';
import SecondaryButton from "../../Buttons/SecondaryButton";
import PurchaseAgentPopup from '../../Popups/PurchaseAgentPopup';

import Text from "../../../shared/lib/widgets/Text";
import Container from "../../../shared/lib/widgets/Container";
import RowWidget from "../../../shared/lib/widgets/RowWidget";
import Loader from '../../../shared/lib/widgets/Loader';

import { Agent } from '../../../entities/Agents/models/types/Agent.types';
import { userPurchaseAgent } from '../../../entities/User/models/services/user.services';
import { fetchUserAgents } from '../../../entities/Agents/models/services/agents.services';

import { $api } from '../../../shared/api/api';
import { useAppDispatch } from '../../../shared/components/useAppDispatch/useAppDispatch';


const AssistantInfo: React.FC = () => {
    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const { id } = useParams<{ id: string }>();

    const [agent, setAgent] = useState<Agent | null>(null);
    const [purchaseAgent, setPurchaseAgent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchAgent = async (agentId: string) => {
            setIsLoading(true);
            try {
                const response = await $api.get(`/agents/${agentId}`);
                setAgent(response.data);
            } catch (error) {
                toast.error(t('messages.errors.agent.search'));
                setAgent(null);
            } finally {
                setIsLoading(false);
            }
        };

        if (id)
            fetchAgent(id);
    }, [id, t]);

    const handlePurchase = () => {
        setPurchaseAgent(false);
        dispatch(userPurchaseAgent(agent!.id));
        dispatch(fetchUserAgents());
    }

    if (isLoading) {
        return (
            <>
                <SubHeader 
                    title='Back to MarketPlace'
                    to='/marketplace'
                    border='1px solid #E4E4E4'
                />

                <RowWidget $alignItems='center' $justifyContent='center' $height='60vh'>
                    <Loader />
                </RowWidget>
            </>
        )
    }

    if (!agent) {
        return (
            <>
                <SubHeader 
                    title='Back to MarketPlace'
                    to='/marketplace'
                    border='1px solid #E4E4E4'
                />

                <Container $margin='20px'>
                    <Container $borderRadius='16px' $border='1px solid grey' $width='100%'>
                        <RowWidget $justifyContent='center' $alignItems='center' $height='80px'>
                            <Text $fontSize='18px' $color='grey'>Agente non trovato. <Link style={{ marginLeft: '8px', color: '#4158E3', fontWeight: 'bold' }} to='/marketplace'>Marketplace</Link></Text>
                        </RowWidget>
                    </Container>
                </Container>
            </>
        )
    }
    
    return (
        <Container>
            {
                purchaseAgent &&
                <PurchaseAgentPopup 
                    onSubmit={handlePurchase}
                    onClose={() => setPurchaseAgent(false)}
                    agentInfo={agent!}
                />
            }
            
            <SubHeader 
                title='Back to MarketPlace'
                to='/marketplace'
                border='1px solid #E4E4E4'
            />
            
            <Container $backgroundColor="#F9FAFC" $padding="32px">
                <Container $backgroundColor="#fff" $width="1024px" $borderRadius="12px" $margin="0 auto" $padding="32px">
                    <RowWidget $flexWrap='wrap' $gap='32px' $width='100%'>
                        <RowWidget $flex='1' $flexDirection='column' $width='100%'>
                            <RowWidget $alignItems="center">
                                <img src={agent?.profileImage} alt="ai" style={{ width: '60px', height: '60px', borderRadius: '50%', objectFit: 'cover', marginRight: '12px' }} />
                                <Text $fontWeight="bold" $fontSize="18px">{agent?.name}</Text>
                            </RowWidget>

                            <Container $height="40px" />

                            <Text $fontWeight="bold" $color="#201F29">Descrizione</Text>

                            <Container $height="8px" />

                            <Text $fontSize="14px" $color="#201F29">{agent?.description}</Text>

                            <Container $height="32px" />

                            <Text $fontWeight="bold" $color="#201F29">Abilità dell'assistente</Text>

                            <Container $height="8px" />
                            
                            {
                                agent?.skills.map((item, index) => 
                                    <Container 
                                        key={index}
                                        $width="100%" 
                                        $padding="16px" 
                                        $borderRadius="8px" 
                                        $backgroundColor="#F6F7F9" 
                                        $margin="6px 0" 
                                    >
                                        <Text $fontSize="14px" $color="#201F29" $fontWeight="bold">{item.name}</Text>

                                        <Container $height="4px" />

                                        {
                                            item.descriptions.map((skillDescription, index) =>
                                                <li key={index} style={{ marginLeft: '8px', color: '#75767D', fontSize: '14px' }}>{skillDescription}</li>
                                            )
                                        }
                                    </Container>
                                )
                            }
                            
                            <Container $height="32px" />

                            <Text $fontWeight="bold">Esempio conversazione</Text>

                            <Container $height="8px" />

                            <Container $backgroundColor="#ECEEFC" $padding="20px 24px" $borderRadius="12px">
                                <RowWidget $margin="0 0 16px 0">
                                    <img src="https://img.freepik.com/free-photo/sunset-silhouettes-trees-mountains-generative-ai_169016-29371.jpg?size=626&ext=jpg&ga=GA1.1.1480204119.1716508800&semt=ais_user" alt="ai" style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover', marginRight: '12px' }} />

                                    <Container $backgroundColor="#FFF" $padding="8px" $borderRadius="12px">
                                        Lorem ipsum dolor sit amet consectetur. Placerat lorem et lectus nec sem amet tortor. Enim convallis ornare enim morbi gravida libero.
                                    </Container>
                                </RowWidget>
                                
                                <RowWidget $margin="0 0 16px 0">
                                    <img src="https://img.freepik.com/free-photo/sunset-silhouettes-trees-mountains-generative-ai_169016-29371.jpg?size=626&ext=jpg&ga=GA1.1.1480204119.1716508800&semt=ais_user" alt="ai" style={{ width: '40px', height: '40px', borderRadius: '50%', objectFit: 'cover', marginRight: '12px' }} />

                                    <Container $padding="8px" $borderRadius="12px" $backgroundColor="#FFF">
                                        Lorem ipsum dolor sit amet consectetur. At egestas iaculis nullam odio massa cursus et. Tristique amet risus purus tempus at. Placerat a porta diam tortor massa bibendum. Mauris ac neque interdum scelerisque. Molestie commodo vitae sit sit quam lectus ut fermentum. Iaculis vitae non dolor viverra bibendum viverra vivamus mi blandit. Pharetra rutrum nunc arcu vitae dui.
                                    </Container>
                                </RowWidget>
                            </Container>
                        </RowWidget>

                        <Container $width="250px">
                            <Container $padding='16px' $backgroundColor='#ECEEFC' $borderRadius='8px'>
                                <RowWidget $flexDirection='column' $alignItems='center'>

                                    <Text $fontWeight="bold">Prezzo assistente</Text>

                                    <Container $height="12px" />

                                    <RowWidget $alignItems="end">
                                        <Text $color="#4158E3" $fontSize="28px">€{agent?.price}</Text>

                                        <Container $width="4px" />

                                        <Text $margin="4px 0" $color="#A4A3A7">/al mese</Text>
                                    </RowWidget>
                                </RowWidget>
                            </Container>

                            <Container $height="16px" />
                            
                            <SecondaryButton 
                                title="Iscriviti"
                                onClick={() => setPurchaseAgent(true)}
                            />
                        </Container>
                    </RowWidget>
                </Container>
            </Container>
        </Container>
    );
}

export default AssistantInfo;