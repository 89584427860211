import { useEffect, useState } from "react";

import { User } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import StartIcon from '../../assets/StarIcon.svg';

import { auth } from "../../configs/firebase-config";

import { StateSchema } from "../../app/providers/StoreProvider/config/StateSchema";

import { updateUserData } from "../../entities/User/models/services/user.services";

import CancelSubscriptionPopup from "../Popups/CancelSubscriptionPopup";

import ProgressBar from '../../shared/lib/widgets/ProgressWidget';
import { subscriptionPlans } from "../../shared/consts/default_values";
import { useAppDispatch } from "../../shared/components/useAppDispatch/useAppDispatch";
import { useAppSelector } from "../../shared/components/useAppSelector/useAppSelector";

import TextButton from "../Buttons/TextButton";
import PrimaryButton from "../Buttons/PrimaryButton";

import Text from "../../shared/lib/widgets/Text";
import Container from "../../shared/lib/widgets/Container";
import RowWidget from "../../shared/lib/widgets/RowWidget";


interface ExtendedUser extends User {
    proactiveRefresh?: {
        user: {
            metadata: {
                createdAt: string;
            };
        };
    };
}

const SubscribedCard = () => {
    const { t } = useTranslation();

    const localLanguage = localStorage.getItem('i18nextLng') || 'it';

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const user = useAppSelector((state: StateSchema) => state.user.userData);
    
    const subscriptions = subscriptionPlans(user?.subscription_id?.id);

    const userPlan = subscriptions.find(subscription => subscription.id === user?.subscription_id?.id)

    const [showCancelPlan, setCancelPlan] = useState(false);

    const handleCancelPlanSubmit = () => {
        dispatch(updateUserData({ id: user?.id, subscription: null }));
        setCancelPlan(false);
    };

    const [activePeriod, setActivePeriod] = useState<{
        daysRemain: number;
        startPeriod: string;
        endPeriod: string;
    }>({
        daysRemain: 0,
        startPeriod: '',
        endPeriod: ''
    });

    
    const formatSubscriptionTime = (createdAt: number, locales: string = 'it-CH') => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };

        const startDate = new Date(createdAt);
        const endDate = new Date(createdAt + 7 * 24 * 60 * 60 * 1000);

        const start = startDate.toLocaleDateString(locales, options);
        const end = endDate.toLocaleDateString(locales, options);

        const now = Date.now();
        const remainingTime = endDate.getTime() - now;
        const remain = Math.max(0, Math.floor(remainingTime / (24 * 60 * 60 * 1000)));

        return { remain, start, end };
    }
    
    useEffect(() => {
        const currentUser = auth?.currentUser as ExtendedUser;
        if (currentUser && currentUser.proactiveRefresh) {
            const createdAtString = currentUser.proactiveRefresh.user.metadata.createdAt;
            const createdAt = parseInt(createdAtString)
            
            const { remain, start, end } = formatSubscriptionTime(createdAt, localLanguage);

            setActivePeriod({
                daysRemain: remain,
                startPeriod: start,
                endPeriod: end,
            });
        }
    }, [localLanguage]);

    return (
        <RowWidget $flex='3'>
            {
                showCancelPlan && 
                <CancelSubscriptionPopup 
                    onSubmit={handleCancelPlanSubmit} 
                    onClose={() => setCancelPlan(false)}
                />
            }

            <Container $border='1px solid #E9E9EA' $padding='20px' $width='100%' $borderRadius='12px'>
                <RowWidget $justifyContent='space-between'>
                    <Container>
                        {
                            userPlan ?
                                <RowWidget $gap='8px' $alignItems='center'>
                                    <Container $backgroundColor='#FF5252' $borderRadius='6px' $padding='4px 10px'>
                                        <Text $fontWeight='bold' $fontSize='18px' $color='#FFF'>{'xxxx'.toUpperCase()}</Text>
                                    </Container>

                                    <Text $fontSize='18px' $fontWeight='bold'>Piano</Text>
                                </RowWidget>
                                :
                                <Container $backgroundColor='#ffa552' $borderRadius='6px' $padding='4px 10px' $width='fit-content'>
                                    <Text $fontWeight='bold' $fontSize='18px' $color='#FFF'>{t('subscriptions.subscription.title')}</Text>
                                </Container>
                        }
                        
                        <Container $height='40px' />

                        {
                            userPlan ?
                                <>
                                    <RowWidget $gap='8px'>
                                        <Text $fontWeight='400'>Prossimo pagamento xxxx.xx EUR il xxxx</Text>

                                        <Text $fontWeight='400' $color="#908F94">(Potrebbe essere applicata l'IVA)</Text>
                                    </RowWidget>

                                    <Container $height='12px' />

                                    <RowWidget $gap='8px'>
                                        <Text $color='#153968' $fontWeight='400' $fontSize='14px'>Rinnovo automatico ogni mese.</Text>

                                        <TextButton 
                                            onClick={() => setCancelPlan(true)}
                                            title='Annulla il rinnovo'
                                            fontSize='14px'
                                        />
                                    </RowWidget>
                                </>
                                :
                                <Container $backgroundColor='#eceefc' $borderRadius='16px' $padding='8px 16px'>
                                    {
                                        activePeriod.daysRemain ?
                                            <Trans
                                                i18nKey="subscriptions.subscription.time_remain"
                                                components={[
                                                    <span key="start" style={{ fontWeight: "bold" }} />,
                                                    <span key="end" style={{ fontWeight: "bold" }} />
                                                ]}
                                                values={{ start: activePeriod.startPeriod, end: activePeriod.endPeriod }}
                                            />
                                            :
                                            <RowWidget $gap='8px' $alignItems='center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" fill="none" viewBox="0 0 22 24">
                                                    <path fill="#201F29" d="M13 13a2 2 0 1 1-3-1.723V7a1 1 0 0 1 2 0v4.277A1.994 1.994 0 0 1 13 13Zm6.06-7.461a11 11 0 1 1-16.12 0l-.672-.747A3.672 3.672 0 0 1 1 5a1 1 0 0 1 0-2c.785 0 1-.215 1-1a1 1 0 1 1 2 0c.006.487-.09.97-.28 1.417l.706.784A10.923 10.923 0 0 1 10 2.051V1a1 1 0 0 1 2 0v1.051A10.92 10.92 0 0 1 17.574 4.2l.706-.784A3.513 3.513 0 0 1 18 2a1 1 0 0 1 2 0c0 .785.215 1 1 1a1 1 0 1 1 0 2 3.672 3.672 0 0 1-1.268-.208l-.672.747ZM20 13a9 9 0 1 0-9 9 9.01 9.01 0 0 0 9-9Z" />
                                                </svg>

                                                <Text>{t('subscriptions.subscription.end')}</Text>
                                            </RowWidget>
                                    }
                                </Container>
                        }

                        <Container $height='32px' />
                        
                        <PrimaryButton
                            onClick={() => navigate('/subscriptions')}
                            leading={StartIcon}
                            title={t('button.upgrade_plan')}
                            $width='180px'
                        />
                    </Container>

                    {
                        true ?
                            <Text 
                                color={activePeriod.daysRemain ? '#4EB61C' : '#ff5252'} 
                                $fontSize='14px' 
                                $fontWeight='bold'
                            >{activePeriod.daysRemain ? 
                                <Trans
                                    i18nKey={activePeriod.daysRemain !== 1 ? "subscriptions.subscription.expire_in.few" : "subscriptions.subscription.expire_in.one"}
                                    components={[
                                        <span key="day" style={{ fontWeight: "bold", color: "#4EB61C" }} />,
                                    ]}
                                    values={{ day: activePeriod.daysRemain }}
                                />
                                : 
                                t('subscriptions.subscription.expired')}
                            </Text>
                            :
                            <ProgressBar maxSpace={20} />
                    }
                </RowWidget>
            </Container>
        </RowWidget>
    );
}

export default SubscribedCard;