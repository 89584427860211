import React, {
    forwardRef,
    // useCallback, 
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';

import User from '../assets/user.svg';

import useChat from '../app/providers/socket/socket';
import { StateSchema } from '../app/providers/StoreProvider/config/StateSchema';

import ChatSidebar from '../components/Sidebar/ChatSidebar';
import MessageCard from '../components/Cards/MessageCard';
import ChatSubHeader from '../components/SubHeader/ChatSubHeader';
// import ShareCasePopup from '../components/Popups/ShareCasePopup';
import SendMessageCard from '../components/Cards/SendMessageCard';

// import { Message } from '../entities/Case/models/types/Case.types';
import { caseReducer } from '../entities/Case/models/slices/CaseSlice';
import { fetchCaseById } from '../entities/Case/models/services/case.services';

import { useAppSelector } from '../shared/components/useAppSelector/useAppSelector';
import { useAppDispatch } from '../shared/components/useAppDispatch/useAppDispatch';
import { ReducersList } from '../shared/components/DynamicModuleLoader/DynamicReducerLoader.types';
import { DynamicReducerLoader } from '../shared/components/DynamicModuleLoader/DynamicReducerLoader';

import Text from '../shared/lib/widgets/Text';
import Loader from '../shared/lib/widgets/Loader';
import Container from '../shared/lib/widgets/Container';
import RowWidget from '../shared/lib/widgets/RowWidget';
import ChatSettingsSidebar from '../components/Sidebar/ChatSettingsSidebar';


const ChatStyle = styled.div`
    background-color: #F9FAFC;
    width: 100%;
    height: calc(100vh - 150px);
    padding: 30px 0 220px 0;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        background: #9fa0a1; 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #aaa; 
    }
`;

const reducers: ReducersList = {
    case: caseReducer,
};

const Chat = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const containerRef = useRef<HTMLDivElement>(null);
    const itemRefs = useRef<Record<number, HTMLDivElement>>({});

    const [showCaseSettings, setShowCaseSettings] = useState(false)

    // const [shareLink, setShareLink] = useState(false)

    // const [loading, setLoading] = useState(false);
    // const [hasLoadedMore, setHasLoadedMore] = useState(false);

    // const [moreMessages, setMoreMessages] = useState<Message[]>([]);

    const user = useAppSelector((state: StateSchema) => state.user);
    const caseData = useAppSelector((state: StateSchema) => state.case);

    const { roomId } = useParams<{ roomId: string }>();
    const { messages, sendMessage, isQueueComplete } = useChat(roomId as string);

    useEffect(() => {
        if (roomId) {
            dispatch(fetchCaseById(roomId as string));

            if (containerRef.current)
                containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    }, [dispatch, roomId]);

    const language = localStorage.getItem('i18nextLng') || 'en';

    const messagesList = useMemo(() => {
        return [
            {
                sender: "chat",
                recipient: "user_proxy",
                content: language === "en" ? "Hello! Welcome to our AI-powered legal platform. I am here to assist you with any legal inquiries you may have. You can choose a specialized assistant by mentioning their area of expertise, such as Criminal Law, Civil Law, or Intellectual Property. Alternatively, feel free to state your specific request, and I'll direct your inquiry to the most relevant assistant. How can I help you today?" : "Ciao! Benvenuto sulla nostra piattaforma legale basata sull'intelligenza artificiale. Sono qui per assisterti con qualsiasi richiesta legale tu possa avere. Puoi scegliere un assistente specializzato menzionando la sua area di competenza, come diritto penale, diritto civile o proprietà intellettuale. In alternativa, sentiti libero di indicare la tua richiesta specifica e indirizzerò la tua richiesta all'assistente più pertinente. Come posso aiutarti oggi?",
            },
            // ...moreMessages, 
            ...(caseData?.case?.messages || []), ...messages];
    }, [
        language,
        // moreMessages, 
        caseData,
        messages
    ]);

    useEffect(() => {
        if (containerRef.current)
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }, [messagesList,
        // loading, 
        t, showCaseSettings]);

    const MessageCardRef = forwardRef(
        ({ messageData, index }: { messageData: any, index: number }, ref: React.LegacyRef<HTMLDivElement>) => {
            const previousMessage = messagesList[index - 1];

            return (
                <div ref={ref}>
                    <MessageCard
                        message={messageData}
                        image={messageData.sender === 'user_proxy' ? user.userData?.image || User : ''}
                        resendMessage={() => sendMessage(previousMessage.content)}
                    />
                </div>
            );
        }
    );

    // const fetchMoreMessages = useCallback(async () => {
    //     setLoading(true);
    //     try {
    //         const oldMessages = await $api.get(`/case/old-messages/${roomId}`);
    //         setMoreMessages((prevMessages) => [...oldMessages.data, ...prevMessages]);
    //     } catch (_) {
    //         toast.info(t('messages.info.no_messages'));
    //     } finally {
    //         setHasLoadedMore(true);
    //         setLoading(false);
    //         setShowPopup(false);
    //     }
    // }, [roomId, t]);

    // const onScroll = useCallback(
    //     (e: React.UIEvent<HTMLDivElement>) => {
    //         const { scrollTop } = e.target as HTMLDivElement;
    //         if (scrollTop <= 0 && !loading && !hasLoadedMore) {
    //             setShowPopup(true);
    //         } else {
    //             setShowPopup(false);
    //         }
    //     },
    //     [loading, hasLoadedMore]
    // );

    const handlerSendMessage = (message: string) => {
        sendMessage(message);
    }

    if (!caseData || (!caseData.case && caseData.isLoading)) {
        return (
            <DynamicReducerLoader reducers={reducers}>
                <RowWidget $justifyContent='center' $alignItems='center' $height='80vh'>
                    <Loader />
                </RowWidget>
            </DynamicReducerLoader>
        );
    }

    const isCollaborator = caseData.case?.collaborators?.some(coll => coll.id === user.userData?.id);
    if (caseData.case?.creator_id !== user?.userData?.id && !isCollaborator) {
        return (
            <DynamicReducerLoader reducers={reducers}>
                <Container $border='1px solid grey' $borderRadius='16px' $margin='16px' $height='80px'>
                    <RowWidget $justifyContent='center' $alignItems='center' $height='100%'>
                        <Text color='grey' $fontSize='18px'>{t('empty_list.no_access')} <Link style={{ marginLeft: '8px', color: '#4158E3', fontWeight: 'bold' }} to='/'>Home</Link></Text>
                    </RowWidget>
                </Container>
            </DynamicReducerLoader>
        )
    }

    return (
        <>
            {/* {
                shareLink &&
                    <ShareCasePopup 
                        onSubmit={(item) => {
                            dispatch(updateCollaborator({ id: caseData?.case?.id, email: item.email, edit: !item.edit }))
                        }}
                        onClose={() => setShareLink(false)}
                    />
            } */}

            <ChatSubHeader
                title={caseData!.case!.name}
                id={caseData!.case!.id}
                showCaseSettings={() => setShowCaseSettings(!showCaseSettings)}
            />

            {
                showCaseSettings ?
                    <ChatSettingsSidebar onClose={() => setShowCaseSettings(false)} />
                    :
                    <ChatStyle ref={containerRef}
                    // onScroll={onScroll}
                    >
                        <ChatSidebar />
                        {
                            messagesList!.map((item, index) => (
                                <MessageCardRef
                                    key={index}
                                    ref={(el: HTMLDivElement) => (itemRefs.current[index] = el)}
                                    index={index}
                                    messageData={item}
                                />
                            ))
                        }

                        <SendMessageCard
                            isLoading={isQueueComplete}
                            onClick={handlerSendMessage}
                        />
                    </ChatStyle>
            }
        </>
    );
}


export default Chat;