import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import SubHeader from '../../SubHeader/SubHeader';

import TutorialCard from '../../Cards/TutorialCard';

import Text from '../../../shared/lib/widgets/Text';
import RowWidget from '../../../shared/lib/widgets/RowWidget';
import Container from '../../../shared/lib/widgets/Container';


const Webinar: React.FC = () => {
    const { t } = useTranslation();

    const [webinarAndTraining, setWebinarAndTraining] = useState([]);
    
    const fetchWebinarAndTraining = () => {
        setWebinarAndTraining([]);
    }

    useEffect(() => {
        fetchWebinarAndTraining();
    }, [])

    return (
        <Container>
            <SubHeader title={t('academy.title')} to='/academy' border='1px solid #E4E4E4' />

            <Container $height='40px' />
            
            <Text $fontSize='32px' $fontWeight='bold' $textAlign='center'>{t('academy.webinars_and_training_sessions.title')}</Text>

            <Container $height='10px' />

            <Text $color='#908F94' $fontWeight='400' $textAlign='center'>{t('academy.webinars_and_training_sessions.sub_description')}</Text>
            
            <Container $height='32px' />

            <RowWidget $justifyContent='center' $gap='16px' $flexWrap='wrap'>
                {
                    webinarAndTraining.length === 0 ?
                        <Container $borderRadius='16px' $border='1px solid grey' $width='100%' $margin='0 16px'>
                            <RowWidget $justifyContent='center' $alignItems='center' $height='80px'>
                                <Text $fontSize='18px' $color='grey'>{t('empty_list.record')}</Text>
                            </RowWidget>
                        </Container>
                        :
                        webinarAndTraining.map((item: any, index) => 
                            <TutorialCard
                                key={index}
                                src={item.image}
                                link={item.link}
                                title={item.name}
                                description={item.description}
                                time={item.time}
                                date={item.date}
                                price={item.price}
                            />
                        )
                }
            </RowWidget>

            <Container $height='32px' />
        </Container>
    );
};

export default Webinar;

