import React from 'react';

import styled from 'styled-components';

import Text from '../../shared/lib/widgets/Text';
import RowWidget from '../../shared/lib/widgets/RowWidget';


interface SwitchToggleProps {
    onToggle: () => void;
    isActive: boolean;
    label?: string;
}

const ToggleContainer = styled.div<{ $isActive: boolean }>`
    width: 36px;
    height: 20px;
    border-radius: 40px;
    background-color: ${(props) => (props.$isActive ? '#4158E3' : '#A2A5A8')};
    position: relative;
    cursor: pointer;
`;

const Ball = styled.div<{ $isActive: boolean }>`
    width: 16px;
    height: 16px;
    top: 2px;
    left: ${(props) => (props.$isActive ? '18px' : '2px')};
    border-radius: 40px;
    position: absolute;
    background-color: #ffffff;
    transition: left 0.3s ease-in-out;
`;

const SwitchToggle: React.FC<SwitchToggleProps> = ({ isActive, onToggle, label }) => {
    const handleToggle = () => {
        onToggle();
    };

    return (
        <RowWidget>
            <ToggleContainer onClick={handleToggle} $isActive={isActive}>
                <Ball $isActive={isActive} />
            </ToggleContainer>

            {label && <Text $margin='0 0 0 8px' $fontWeight="600" $fontSize="16px">{label}</Text>}
        </RowWidget>
    );
};

export default SwitchToggle;
